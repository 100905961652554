<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
import tours from '@/config/shepherd-steps'
import { constants } from '@/shared/constants'
// const TITLE_PREFIX = '[Z!Hub] '
const TOUR_INTRO = '_intro'

export default {
  name: 'BaseShepherd',
  data: () => ({
    testing: false,
    active: tours._config.active,
    tour: {},
    tourSteps: [],
  }),
  computed: {
    ...mapGetters(['currentUser']),
    tourName() {
      return this.$route.name
    },
    storagePrefix() {
      return constants.LOCAL_STORAGE_PREFIX_TOUR + this.currentUser.id
    },
  },
  watch: {
    '$route.name': {
      handler() {
        this.resetTour()
        if (process.client && this.active) {
          // the use of clientWidth from document.querySelector in elementIsHidden()
          // was causing the tour to not display
          // because the elements weren't yet visible
          // so I added a timeout, which reolved the issue
          setTimeout(() => {
            this.setTour()
          }, 300) // allow DOM to fully load
        }
      },
    },
  },
  mounted() {
    if (this.active) {
      this.setTour()
    }
  },
  methods: {
    elementIsHidden(el) {
      // @FIXME not working when navigating from another route
      const e = document.querySelector(el)
      return !e || !e.clientWidth
    },
    tourIntro() {
      const seen = localStorage.getItem(this.storagePrefix + TOUR_INTRO)
      if (!seen || this.testing) {
        // if (!this.tour.steps.findIndex(({ id }) => id === TOUR_INTRO)) {
        this.tour.steps = [...tours[TOUR_INTRO].steps, ...this.tour.steps]
        // }
        localStorage.setItem(this.storagePrefix + TOUR_INTRO, true)
      }
    },
    startTour() {
      this.tourIntro()
      const steps = [...this.tour.steps]
      const shepherd = this.$shepherd({
        useModalOverlay: tours._config.useModalOverlay,
        defaultStepOptions: tours._config.defaultStepOptions,
      })

      // add steps
      for (const i in steps) {
        // check if step element is hidden
        if (steps[i].attachTo && this.elementIsHidden(steps[i].attachTo.element)) {
          continue
        }
        // initialize the buttons
        steps[i].buttons = []

        if (i > 0) {
          steps[i].buttons.push({
            text: 'Prev',
            classes: 'btn btn-info',
            action: shepherd.back,
          })
        }

        if (i < steps.length - 1) {
          /**
           * @TODO make cancel a dropdown w/ options
           * @example see below
           */
          // steps[i].buttons.push({
          //   type: 'dropdown',
          //   children: [
          //     {
          //       text: 'One Hour',
          //       classes: 'btn btn-warning',
          //       action: shepherd.cancel1Hour
          //     },
          //     {
          //       text: 'Three Hours',
          //       classes: 'btn btn-warning',
          //       action: shepherd.cancel3Hours
          //     },
          //     {
          //       text: 'Today',
          //       classes: 'btn btn-warning',
          //       action: shepherd.cancelToday
          //     }
          //   ],
          //   text: 'Dismiss',
          //   classes: 'btn btn-warning',
          //   action: null
          // })
          steps[i].buttons.push({
            text: 'Next',
            classes: 'btn btn-success',
            action: shepherd.next,
          })
          steps[i].buttons.push({
            text: 'Finish',
            classes: 'btn btn-danger',
            action: shepherd.complete,
          })
        } else {
          steps[i].buttons.push({
            text: 'Finish',
            classes: 'btn btn-success tour-end',
            action: shepherd.complete,
          })
        }
        shepherd.addStep(steps[i])
      }

      ['complete'].forEach((event) =>
        shepherd.on(event, () => {
          localStorage.setItem(this.storagePrefix + this.tourName, true)
        })
      )

      shepherd.start()
    },
    resetTour() {
      // localStorage.removeItem(this.storagePrefix + this.tourName)
      this.tour = {}
      this.tourSteps = []
    },
    setTour() {
      this.tour = {}
      // check for tour and existing steps
      if (
        !(
          tours[this.tourName] &&
          tours[this.tourName].active &&
          tours[this.tourName].steps.length
        )
      ) {
        return
      }
      // check if tour is already complete
      const complete = localStorage.getItem(this.storagePrefix + this.tourName)
      if (!this.testing && complete) {
        return
      }
      // start tour
      this.tour = { ...tours[this.tourName] }
      this.tourSteps = [...this.tour.steps]
      this.$nextTick(() => {
        this.startTour()
      })
    },
  },
}
</script>

<style>
.shepherd-text {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.shepherd-footer {
  position: relative;
  vertical-align: middle;
  border-top: solid #444 1px;
  padding-top: 0.75rem;
}
html:not([dir='rtl']) .shepherd-footer > .btn:not(:last-child):not(.dropdown-toggle),
html:not([dir='rtl']) .shepherd-footer > .shepherd-footer:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.shepherd-footer > .btn:not(:last-child):not(.dropdown-toggle),
.shepherd-footer > .shepherd-footer:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.shepherd-footer > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>
