<template>
  <v-select
    v-if="ready"
    id="franchise"
    ref="selectFranchise"
    class="form-control"
    :value="optionsValue"
    :options="franchiseList"
    :required="required"
    @input="onInput"
  >
  </v-select>
  <div v-else>LOADING...</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'BaseFormSelectFranchise',
  components: { vSelect },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    primaryKey: {
      type: String,
      default: () => ('franchiseCode')
    },
    defaultCode: {
      type: [ String, Number ],
      default: null
    },
    defaultLabel: {
      type: [ String, Number ],
      default: () => ('Franchise')
    }
  },
  data () {
    return {
      optionsValue: null,
      defaultValue: {
        code: this.defaultCode,
        label: this.defaultLabel
      },
      ready: false
    }
  },
  computed: {
    ...mapGetters(['franchises']),
    franchiseList () {
      let franchiseList = [ ...this.franchises ]
      franchiseList = franchiseList.map(f => ({
        code: `${f[this.primaryKey]}`,
        label: f.director[0]
        ? `${f.director[0].profile.fname.replace('&amp;','&')} ${f.director[0].profile.lname} (${f.franchiseCode})`
        : ` - (${f.franchiseCode})`
      }))
      franchiseList.unshift(this.defaultValue)
      return franchiseList
    }
  },
  methods: {
    ...mapActions(['getFranchises']),
    onInput (selected) {
      this.optionsValue = selected ? selected : this.defaultValue
      this.$emit('input', this.optionsValue.code)
    },
    setSelected (code) {
      this.optionsValue = this.franchiseList.find(e => e.code === code) || this.defaultValue
    },
    focus () {
      this.$refs.selectFranchise.focus()
    }
  },
  async mounted () {
    await this.getFranchises({
      active: true,
      pagination: false
    })
    this.ready = true
    this.setSelected(this.value)
  }
}
</script>

<style lang="scss">
#franchise {
  .vs__dropdown-toggle {
    border: none;
  }
  .vs__selected {
    margin: 0;
  }
  .vs__actions {
    padding: 0;
  }
}
</style>