/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '../../shared/constants'
import {
	SET_CLIENTS,
  ADD_CLIENT,
  SET_FRANCHISE_CLIENTS,
  ADD_FRANCHISE_CLIENT,
	SET_CLIENTS_TOTAL_UNFILTERED,
	SET_CLIENT,
	SET_CLIENT_STATUS_OPTIONS,
  ADD_CLIENT_PARENT,
  SET_FRANCHISE_SUBSCTIPTIONS,
  ADD_FRANCHISE_SUBSCTIPTION
} from '../mutation-types'

const state = {
  clients: [],
  franchiseClient: {},
  franchiseClients: [],
  clientsTotalUnfiltered: 0,
  client: {},
  clientParents: [],
  statusOptions: [],
  franchiseSubscriptions: []
}

const getters = {
  clients: state => state.clients,
  franchiseClient: state => state.franchiseClient,
  franchiseClients: state => state.franchiseClients,
  clientsTotalUnfiltered: state => state.clientsTotalUnfiltered,
  client: state => state.client,
  clientStatusOptions: state => state.statusOptions,
  clientContactRelOpts: () => {
    return constants.CLIENT_CONTACT_RELATIONSHIPS
  },
  franchiseSubscriptions: state => state.franchiseSubscriptions
}

const actions = {

  async getClients ({ commit }, params) {
    const resp = await HTTP.get(constants.API_URI_CLIENTS, {
      params: params
    })
    commit(SET_CLIENTS, resp.data['hydra:member'])
    commit(SET_CLIENTS_TOTAL_UNFILTERED, resp.data['hydra:totalItems'])
  },

  async getFranchiseClients ({ commit }, params) {
    const resp = await HTTP.get(constants.API_URI_FRANCHISE_CLIENTS, { params })
    commit(SET_FRANCHISE_CLIENTS, resp.data['hydra:member'])
    return resp.data['hydra:member']
  },

  getFranchiseClient ({ dispatch }, { franchiseCode, clientId }) {
    // @TODO validate
    const resp = dispatch('getFranchiseClients', {
      franchiseCode,
      client: clientId
    })
    return resp[0] || {}
  },

  async postFranchiseClient ({ commit }, payload) {
    const resp = await HTTP.post(constants.API_URI_FRANCHISE_CLIENTS, payload)
    commit(ADD_CLIENT, resp.data)
    return resp
  },

  async putFranchiseClient ({ commit }, payload) {
    const resp = await HTTP.put(constants.API_URI_FRANCHISE_CLIENTS + '/' + payload.id, payload)
    return resp
  },

  resetClients ({ commit }) {
    commit(SET_CLIENTS, [])
    commit(SET_CLIENTS_TOTAL_UNFILTERED, 0)
  },

  async getClientStatusOptions ({ commit }) {
    const resp = await HTTP.get(constants.API_URI_CLIENT_STATUS_OPTIONS)
    commit('SET_CLIENT_STATUS_OPTIONS', resp.data['hydra:member'])
  },

  // get single client
  async getClient ({ commit }, clientId) {
    const resp = await HTTP.get(constants.API_URI_CLIENTS + '/' + clientId)
    commit(SET_CLIENT, resp.data)
  },

  async postClient ({ commit }, payload) {
    let clientProfile = {}
    if (payload.clientProfile) {
      clientProfile = payload.clientProfile
      delete payload.clientProfile
    }
    const client = await HTTP.post(constants.API_URI_CLIENTS, payload)
    clientProfile.client = client.data.id
    // post client profile
    const clientProfileData = await HTTP.post(constants.API_URI_CLIENT_PROFILES, clientProfile)
      .then(clientProfile_1 => (clientProfile_1.data))
    client.data.clientProfile = clientProfileData
    commit(SET_CLIENT, client.data)
    return await client.data
  },

  async putClient ({ commit }, payload) {
    let clientProfile = {}
    if (payload.clientProfile) {
      clientProfile = payload.clientProfile
      delete payload.clientProfile
    }
    const client = await HTTP.put(constants.API_URI_CLIENTS + '/' + payload.id, payload)
    // post client profile
    const clientProfileData = await HTTP.put(constants.API_URI_CLIENT_PROFILES + '/' + clientProfile.id, clientProfile)
      .then(clientProfile_1 => (clientProfile_1.data))
    client.data.clientProfile = clientProfileData
    commit(SET_CLIENT, client.data)
    return client.data
  },

  resetClient ({ commit }) {
    commit(SET_CLIENT, {})
  },

  async postClientParent ({ commit }, payload) {
    const resp = await HTTP.post(constants.API_URI_CLIENT_PARENTS, payload)
    commit(ADD_CLIENT_PARENT, resp.data)
    return resp.data
  },

  // subscriptions
  async getFranchiseSubscriptions ({ commit }, params) {
    const resp = await HTTP.get(constants.API_URI_FRANCHISE_SUBSCRIPTIONS, { params })
    commit(SET_FRANCHISE_SUBSCTIPTIONS, resp.data['hydra:member'])
    return resp.data['hydra:member']
  },

  getFranchiseSubscription ({ dispatch }, id) {
    // @TODO validate
    const resp = dispatch('Subscriptions', {
      franchiseCode,
      client: clientId
    })
    return resp[0] || {}
  },

  async postFranchiseSubscription ({ commit }, payload) {
    const resp = await HTTP.post(constants.API_URI_FRANCHISE_SUBSCRIPTIONS, payload)
    commit(ADD_FRANCHISE_SUBSCTIPTION, resp.data)
    return resp
  },

  async putFranchiseSubscription ({ commit }, payload) {
    const resp = await HTTP.put(constants.API_URI_FRANCHISE_SUBSCRIPTIONS + '/' + payload.id, payload)
    return resp
  }
}

const mutations = {
  [SET_CLIENTS] (state, data) {
    state.clients = data
  },
  [ADD_CLIENT] (state, data) {
    state.clients.push(data)
  },
  [SET_FRANCHISE_CLIENTS] (state, data) {
    state.franchiseClients = data
  },
  [ADD_FRANCHISE_CLIENT] (state, data) {
    state.franchiseClients.push(data)
  },
  [SET_CLIENTS_TOTAL_UNFILTERED] (state, num) {
    state.clientsTotalUnfiltered = num
  },
  [SET_CLIENT] (state, client) {
    state.client = client
  },
  [SET_CLIENT_STATUS_OPTIONS] (state, options) {
    state.statusOptions = options
  },
  [ADD_CLIENT_PARENT] (state, data) {
    state.clientParents.push(data)
  },
  [SET_FRANCHISE_SUBSCTIPTIONS] (state, data) {
    state.franchiseSubscriptions = data
  },
  [ADD_FRANCHISE_SUBSCTIPTION] (state, data) {
    state.franchiseSubscriptions.push(data)
  }
}
export default {

  state,
  getters,
  actions,
  mutations
}
