<template>
  <v-autocomplete 
    ref="autocomplete"
    v-model="selected"
    id="franchise-query"
    :auto-select-one-item="autoSelectOneItem"
    :min-len="minLen"
    :items="searchOptions" 
    :get-label="(e) => (e ? e.name : null)"
    :component-item='aCTemplate' 
    :input-attrs="{ placeholder: 'Franchise Code / director', class: 'form-control' }"
    :wait="debounce"
    @update-items="setSearchOptions"
  ></v-autocomplete>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AutoCompleteItemTemplate from '@/components/AutoComplete/AutoCompleteItemTemplate.vue'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'BaseFormFranchise',
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    primaryKey: {
      type: String,
      default: () => ('franchiseCode')
    },
    defaultCode: {
      type: [ String, Number ],
      default: null
    },
    defaultLabel: {
      type: [ String, Number ],
      default: () => ('Franchise')
    },
    active: {
      type: Boolean,
      default: null
    },
    debounce: {
      type: Number,
      default: 250
    },
    autoSelectOneItem: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      aCTemplate: AutoCompleteItemTemplate,
      selected: null,
      minLen: 1,
      searchOptions: [],

      optionsValue: null,
      defaultValue: {
        code: this.defaultCode,
        label: this.defaultLabel
      },
      ready: false
    }
  },
  computed: {
    ...mapGetters(['searchResults']),
    franchiseList () {
      let franchiseList = [ ...this.franchises ]
      franchiseList = franchiseList.map(f => ({
        code: `${f[this.primaryKey]}`,
        label: f.director[0]
        ? `${f.director[0].profile.fname.replace('&amp;','&')} ${f.director[0].profile.lname} (${f.franchiseCode})`
        : ` - (${f.franchiseCode})`
      }))
      franchiseList.unshift(this.defaultValue)
      return franchiseList
    }
  },
  methods: {
    ...mapActions(['systemSearch']),
    focus () {
      this.$refs.autocomplete.focus()
    },
    /**
     * search methods
     */
    async setSearchOptions (text) {
      this.selected = null
      this.searchOptions = []
      if (! text) { return }

      const systemSearch = await this.systemSearch({
        search: 'franchises',
        active: this.active,
        query: text
      }).then(resp => {
        let results = [ ...this.searchResults ]
        if (!results.length) {
          return null
        }
        for (let k in results) {
          if (results[k].franchiseCode) {
            let option = {
              id: results[k].franchiseCode,
              name: `${results[k].franchiseCode} / ${results[k].director.profile.fname} ${results[k].director.profile.lname}`,
              description: null
            }
            this.searchOptions.push(option)
          }
        }
        return resp
      })

      return systemSearch
    },
    setLabel (item) {
      if (!item) {
        return null
      }
      return item.name
    },
    async setSearchOptionsFromValue () {
      if (this.value && (!this.selected || this.value != this.selected.id)) {
        await this.setSearchOptions(this.value)
        this.$refs.autocomplete.onSelectItem(this.searchOptions[0])
      } else if (!this.value) {
        this.selected = null
      }
    }
  },
  mounted () {
    this.setSearchOptionsFromValue()
  },
  watch: {
    value () {
      this.setSearchOptionsFromValue()
    },
    selected: {
      handler (val) {
        this.$emit('input', val ? val.id : null)
        this.$emit('selected-code', val ? val.id : null)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
#franchise-query.v-autocomplete {
  position: relative !important;
}

#franchise-query .v-autocomplete-list {
  position: absolute !important;
  top: 36px;
  left: 0;
  width: 100%;
  z-index: 999;
}

#franchise-query .v-autocomplete-list-item {
  background-color: #FFFF;
}
</style>