import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import i18n from '@/plugins/i18n'
import CoreuiVue from '@coreui/vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import updateLocale from 'dayjs/plugin/updateLocale'
import VueGtag from 'vue-gtag'
import { iconsSet as icons } from './assets/icons/icons.js'
import Autocomplete from 'v-autocomplete'
import globalMixins from '@/shared/globalMixins'
import FlagIcon from 'vue-flag-icon'
import JsonExcel from '@/components/JsonExcel'
import VueCompositionApi from '@vue/composition-api'
import ErrorService from "./services/error-service"
// import shepherd from '@/plugins/vue-shepherd'
import VueShepherd from 'vue-shepherd'
import { abilitiesPlugin } from '@casl/vue'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './registerServiceWorker'

// Globally register all `Base`-prefixed components
import '@/components/_globals'

// config items
Vue.config.performance = true
// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'
// Vue.config.errorHandler = function (err, vm, info) {
//   console.error('eh', err, info)
// }
Vue.config.errorHandler = (error) => ErrorService.onError(error)

// dayjs plugins
dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  invalidDate : '-' // overwrites "Invalid Date"
})

// use
Vue.use(Autocomplete)
Vue.use(BootstrapVue)
Vue.use(CoreuiVue)
Vue.use(FlagIcon)
Vue.use(IconsPlugin)
Vue.use(VueCompositionApi)
Vue.use(VueGtag, {
  config: { id: "G-ZRF5MZEGHT" }
}, router)
Vue.use(VueShepherd)
Vue.use(abilitiesPlugin, store.getters.ability)

Vue.component('DownloadExcel', JsonExcel)

// prototypes
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$auth = {}
Object.defineProperties(Vue.prototype, {
  $moment: { get() { return dayjs } },
  $d: { get() { return dayjs } } // i.e., $datetime
});

// global mixins
Vue.mixin(globalMixins)

new Vue({
  components: {
    App
  },
  el: '#app',
  i18n,
  icons,
  router,
  store,
  template: '<App/>'
})
