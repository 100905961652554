export default {

  /**
   * @url https://shepherdjs.dev/docs/
   */
  _config: {
    /**
     * enables/disables tour feature
     */
    active: true,

    useModalOverlay: true,

    /**
     * options applied to all steps, 
     * overridden by custom options
     */
    defaultStepOptions: {
      classes: '',
      scrollTo: true,
      cancelIcon: { enabled: true },
      canClickTarget: false
    }
  },

  _intro: {
    steps: [
      {
        title: 'Welcome to the New Z!Hub!',
        text: `<p>This is a quick tour of the area.</p>
        <p>> Clicking <button aria-label="Close Tour" class="shepherd-cancel-icon" 
        type="button"><span aria-hidden="true">×</span></button> 
        at any time will dismiss the tour but you'll see it again next time you visit the page.</p>
        <p>> Clicking <button class="btn btn-sm btn-danger">Finish</button> will dismiss the tour permanently.</p>
        <p>> You can also use your keyboard's arrow keys to walk through the tour.</p>`
      }
    ]
  },

  /* DASHBOARD */
  Dashboard: {
    active: true,
    steps: [
      {
        title: 'Sidebar Navigation',
        text: `Providing quick access to collections of content.`,
        attachTo: {
          element: '.c-sidebar', on: 'right'
        }
      },
      {
        title: 'Minimize Navigation',
        text: `You can maximize your content space and still have navigation by minimizing the Sidebar Navigation.`,
        attachTo: {
          element: '.c-sidebar-minimizer', on: 'right'
        }
      },
      /* {
        title: 'Hide Navigation',
        text: `You can completely hide the navigation with this toggle button.`,
        attachTo: {
          element: '.c-header-toggler-icon', on: 'bottom-start'
        }
      }, */
      {
        title: 'Breadcrumb Navigation',
        text: `You can use this section to reference where you are and to return to parent pages.`,
        attachTo: {
          element: '.breadcrumb', on: 'bottom-start'
        }
      },
      {
        title: 'Account & Settings',
        text: `Here you will find your settings, personal profile and notifications.`,
        attachTo: {
          element: '.c-avatar', on: 'left'
        }
      },
      {
        title: 'The Z! Hub Dashboard',
        text: `<b>Once this section is complete</b>, it will provide an overall glimpse of your account.`,
        attachTo: {
          element: '.c-main', on: 'auto'
        }
      }
    ]
  },

  /* SSC */
  SscBooking: {
    active: true,
    steps: [
      {
        title: 'SSC Booking Interface',
        text: `This is where we will display useful information about the SSC Booking Interface.
        I haven't gotten that far yet and will need to come up with a way to separate the content; e.g., pre-search and post-search.`,
        classes: 'modal-lg'
      }
    ]
  },
  
  /* FRANCHISE DIRECTORY */
  FranchiseDirectory: {
    active: true,
    steps: [
      {
        title: 'The Franchise Directory',
        //- Use the <b>Action</b> options to <b>view in a new tab</b> or <b>edit</b> the details<br />
        text: `Here you can quickly lookup up franchises and reference their information.<br /><br />
        
        - Click the <b>code</b> to pull up the <b>franchise deails</b> in a window<br />
        - Click the <b>name</b> to pull up the <b>director's profile</b> in a window`,
        classes: 'modal-lg'
      }
    ]
  },
}
