<template>
  <b-row no-gutters class="rounded">
    <b-col cols="12" lg="2"
      ><label class="input-group-text rounded-0" for="datepicker-from">{{
        label
      }}</label></b-col
    >
    <b-col cols="12" lg="5">
      <!-- selected.from -->
      <b-form-datepicker
        v-model="selected.from"
        :debounce="gInputDebounce"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        :placeholder="placeholderFrom"
        id="datepicker-from"
        class="rounded-0"
        today-button
        reset-button
        close-button
      ></b-form-datepicker>
    </b-col>
    <b-col cols="12" lg="5">
      <!-- dateSelectOption -->
      <b-button-group v-if="dateSelectOption !== 'span'" class="d-flex">
        <b-button
          v-for="option in dateStartOptions"
          class="rounded-0"
          @click="onDateSelectOptionChange(option.value)"
          :title="option.title"
          :pressed="selected.from && dateSelectOption === option.value"
          :disabled="!selected.from && option.value !== 'span'"
          :key="option.value"
          >{{ option.text }}</b-button
        >
      </b-button-group>

      <!-- selected.to -->
      <b-form-datepicker
        v-else
        v-model="selected.to"
        :debounce="gInputDebounce"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        :placeholder="placeholderTo"
        @input="checkSelectedToValue"
        today-button
        reset-button
        close-button
      ></b-form-datepicker>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'BaseFormDatepicker',
  props: {
    label: {
      type: String,
      default: 'Dates',
    },
    placeholderFrom: {
      type: String,
      default: 'From',
    },
    placeholderTo: {
      type: String,
      default: 'To',
    },
    value: {
      type: Object,
      default: () => ({
        from: null,
        to: null,
      }),
    },
  },
  data: () => ({
    selected: {
      from: null,
      to: null,
    },
    dateSelectOption: 'inclusive',
    dateStartOptions: [
      { value: 'inclusive', text: 'Incl', title: 'Include dates after', default: true },
      { value: 'exclusive', text: 'Excl', title: 'Exclude dates after' },
      { value: 'span', text: 'To', title: 'Specify an end date' },
    ],
  }),
  mounted() {
    this.selected = this.value
  },
  watch: {
    selected: {
      handler() {
        this.$emit('input', this.selected)
      },
      deep: true,
    },
  },
  methods: {
    checkSelectedToValue() {
      if (this.selected.to === '') {
        this.dateSelectOption = this.dateStartOptions.find((e) => e.default).value
      }
    },
    onDateSelectOptionChange(e) {
      this.dateSelectOption = e
      switch (e) {
        case 'inclusive':
          this.selected.to = null
          break
        case 'exclusive':
          this.selected.to = this.selected.from
          break
        case 'span':
          // this.selected.to = this.selected.from
          this.selected.to = this.$d().format('YYYY-MM-DD')
          break
      }
    },
  },
}
</script>
