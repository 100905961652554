export default {
    'US': {
        'AL': 'Alabama',  
        'AK': 'Alaska',  
        'AZ': 'Arizona',  
        'AR': 'Arkansas',  
        'CA': 'California',  
        'CO': 'Colorado',  
        'CT': 'Connecticut',  
        'DE': 'Delaware',  
        'DC': 'District Of Columbia',  
        'FL': 'Florida',  
        'GA': 'Georgia',  
        'HI': 'Hawaii',  
        'ID': 'Idaho',  
        'IL': 'Illinois',  
        'IN': 'Indiana',  
        'IA': 'Iowa',  
        'KS': 'Kansas',  
        'KY': 'Kentucky',  
        'LA': 'Louisiana',  
        'ME': 'Maine',  
        'MD': 'Maryland',  
        'MA': 'Massachusetts',  
        'MI': 'Michigan',  
        'MN': 'Minnesota',  
        'MS': 'Mississippi',  
        'MO': 'Missouri',  
        'MT': 'Montana',
        'NE': 'Nebraska',
        'NV': 'Nevada',
        'NH': 'New Hampshire',
        'NJ': 'New Jersey',
        'NM': 'New Mexico',
        'NY': 'New York',
        'NC': 'North Carolina',
        'ND': 'North Dakota',
        'OH': 'Ohio',  
        'OK': 'Oklahoma',  
        'OR': 'Oregon',  
        'PA': 'Pennsylvania',  
        'RI': 'Rhode Island',  
        'SC': 'South Carolina',  
        'SD': 'South Dakota',
        'TN': 'Tennessee',  
        'TX': 'Texas',  
        'UT': 'Utah',  
        'VT': 'Vermont',  
        'VA': 'Virginia',  
        'WA': 'Washington',  
        'WV': 'West Virginia',  
        'WI': 'Wisconsin',  
        'WY': 'Wyoming'
    },
    'CA': {
        'AB': 'Alberta',
        'BC': 'British Columbia',
        'MB': 'Manitoba',
        'NB': 'New Brunswick',
        'NF': 'Newfoundland',
        'NT': 'Northwest Territories',
        'NS': 'Nova Scotia',
        'NU': 'Nunavut',
        'ON': 'Ontario',
        'PE': 'Prince Edward Island',
        'QC': 'Quebec',
        'SK': 'Saskatchewan',
        'YT': 'Yukon Territory'
    },
    'TR': {
        'Adana': 'Adana',
        'Adıyaman': 'Adıyaman',
        'Afyonkarahisar': 'Afyonkarahisar',
        'Ağrı': 'Ağrı',
        'Aksaray': 'Aksaray',
        'Amasya': 'Amasya',
        'Ankara': 'Ankara',
        'Antalya': 'Antalya',
        'Ardahan': 'Ardahan',
        'Artvin': 'Artvin',
        'Aydın': 'Aydın',
        'Balıkesir': 'Balıkesir',
        'Bartın': 'Bartın',
        'Batman': 'Batman',
        'Bayburt': 'Bayburt',
        'Bilecik': 'Bilecik',
        'Bingöl': 'Bingöl',
        'Bitlis': 'Bitlis',
        'Bolu': 'Bolu',
        'Burdur': 'Burdur',
        'Bursa': 'Bursa',
        'Çanakkale': 'Çanakkale',
        'Çankırı': 'Çankırı',
        'Çorum': 'Çorum',
        'Denizli': 'Denizli',
        'Diyarbakır': 'Diyarbakır',
        'Düzce': 'Düzce',
        'Edirne': 'Edirne',
        'Elazığ': 'Elazığ',
        'Erzincan': 'Erzincan',
        'Erzurum': 'Erzurum',
        'Eskisehir': 'Eskisehir',
        'Gaziantep': 'Gaziantep',
        'Giresun': 'Giresun',
        'Gümüşhane': 'Gümüşhane',
        'Hakkari': 'Hakkari',
        'Hatay': 'Hatay',
        'Iğdır': 'Iğdır',
        'Isparta': 'Isparta',
        'Istanbul-I (Thrace)': 'Istanbul-I (Thrace)',
        'Istanbul-II (Anatolia)': 'Istanbul-II (Anatolia)',
        'İzmir': 'İzmir',
        'Kahramanmaraş': 'Kahramanmaraş',
        'Karabük': 'Karabük',
        'Karaman': 'Karaman',
        'Kars': 'Kars',
        'Kastamonu': 'Kastamonu',
        'Kayseri': 'Kayseri',
        'Kilis': 'Kilis',
        'Kırıkkale': 'Kırıkkale',
        'Kırklareli': 'Kırklareli',
        'Kırşehir': 'Kırşehir',
        'Kocaeli (İzmit)': 'Kocaeli (İzmit)',
        'Konya': 'Konya',
        'Kütahya': 'Kütahya',
        'Malatya': 'Malatya',
        'Manisa': 'Manisa',
        'Mardin': 'Mardin',
        'Mersin': 'Mersin',
        'Muğla': 'Muğla',
        'Muş': 'Muş',
        'Nevşehir': 'Nevşehir',
        'Niğde': 'Niğde',
        'Ordu': 'Ordu',
        'Osmaniye': 'Osmaniye',
        'Rize': 'Rize',
        'Sakarya (Adapazarı)': 'Sakarya (Adapazarı)',
        'Samsun': 'Samsun',
        'Şanlıurfa': 'Şanlıurfa',
        'Siirt': 'Siirt',
        'Sinop': 'Sinop',
        'Sivas': 'Sivas',
        'Şırnak': 'Şırnak',
        'Tekirdağ': 'Tekirdağ',
        'Tokat': 'Tokat',
        'Trabzon': 'Trabzon',
        'Tunceli': 'Tunceli',
        'Uşak': 'Uşak',
        'Van': 'Van',
        'Yalova': 'Yalova',
        'Yozgat': 'Yozgat',
        'Zonguldak': 'Zonguldak'
    }
}
