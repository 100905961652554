/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import {
  SET_ACTIVE_FRANCHISE,
  SET_FRANCHISES,
  SET_FRANCHISE,
  SET_FRANCHISE_SETTINGS,
  SET_FRANCHISE_PHONES,
  SET_FRANCHISE_SERVICE_GROUPS,
  SET_FRANCHISE_MARKETING,
  SET_GLOBAL_MARKETING,
  SET_FRANCHISE_STAFF,
  SET_SSC_NOTES,
  ADD_SSC_NOTE,
  SET_CLIENT_LEADS,
  SET_SERVICE_GROUPS,
  SET_SERVICE_GROUP_DATA,
  SET_GROUP_SERVICES,
  SET_ACTIVE_GROUP_SERVICES,
  SET_SCHOOLS,
  SET_DEFAULT_CONSULT_HOURS,
  SET_STAFF_EVENTS,
  SET_JOBS,
  SET_VIEW_FRANCHISE_CODE,
  SET_VIEW_FRANCHISE_CODE_NORESET
} from '../mutation-types'

// initial state
const state = {
  franchise: {},
  franchises: [],
  activeFranchise: null,
  appointments: [],
  clientLeads: [],
  defaultConsultHours: null,
  franchiseStaff: [],
  sscNotes: [],
  marketingGlobal: [],
  activeFranchiseGroupServices: [],
  jobs: [],
  staffEvents: [],
  serviceGroups: [],
  groupServices: [],
  serviceGroupData: {},
  viewFranchiseCode: null,
  viewFranchiseCodeNoReset: false
}

// getters
const getters = {
  // active franchise
  activeFranchise: state => state.activeFranchise,
  appointments: state => state.appointments,
  clientLeads: state => state.clientLeads,
  defaultConsultHours: state => state.defaultConsultHours,
  franchise: state => state.franchise,
  franchises: state => state.franchises,
  franchiseStaff: state => state.franchiseStaff,
  sscNotes: state => state.sscNotes,
  marketingGlobal: state => state.marketingGlobal,
  groupServices: state => state.groupServices,
  activeFranchiseGroupServices: state => state.activeFranchiseGroupServices,
  jobs: state => state.jobs,
  serviceGroups: state => state.serviceGroups,
  serviceGroupData: state => state.serviceGroupData,
  staffEvents: state => state.staffEvents,
  viewFranchiseCode: state => state.viewFranchiseCode,
  viewFranchiseCodeNoReset: state => state.viewFranchiseCodeNoReset
}

// actions
const actions = {

  // franchise
  getFranchise ({ commit, dispatch }, franchiseCode) {
    return HTTP.get(constants.API_URI_FRANCHISES, {
      params: {
        franchiseCode
      }
    }).then(async (resp) => {
      const franchise = resp.data['hydra:member'][0]
      // get associated phone numbers
      const phones = await dispatch('getFranchisePhones', franchise.franchiseCode)
      franchise.phones = phones
      const terr = await dispatch('getFranchiseTerritories', franchise.franchiseCode)
      franchise.territories = terr
      commit(SET_FRANCHISE, franchise)
      return franchise
    })
  },

  getFranchiseTerritories ({ commit, dispatch }, franchiseCode) {
    return HTTP.get(constants.API_URI_ZIP_CODES, {
      params: {
        franchiseCode
      }
    }).then(resp => {
      return resp.data['hydra:member']
    })
  },

  resetFranchise ({ commit }) {
    commit(SET_FRANCHISE, {})
  },

  /* async getSSCData ({ commit }, params) {
    const actions = {
      getFranchiseServiceGroups: params,
      getFranchiseSchools: params,
      getFranchiseSettings: params,
      getFranchiseMarketing: params
    }
    for (const k in actions) {
      await dispatch(k, actions[k])
    }
  }, */

  getFranchiseSettings ({ commit }, franchiseCode) {
    return HTTP.get(constants.API_URI_FRANCHISE_SETTINGS, {
      params: {
        franchiseCode
      }
    }).then(resp => {
      commit(SET_FRANCHISE_SETTINGS, resp.data['hydra:member'])
    })
  },

  // franchise directory
  getFranchiseDirectory ({ commit }, URLParams) {
    let vm = this
    vm.dispatch('actionPending', 'getFranchiseDirectory')
    return new Promise((resolve, reject) => {
      this._vm.$http('franchise/directory', {}, URLParams).then(function (result) {
        commit('setDirectory', result.data.directory)
        vm.dispatch('actionComplete', 'getFranchiseDirectory')
        resolve()
      })
    })
  },

  getFranchises ({ commit }, params) {
    return HTTP.get(constants.API_URI_FRANCHISES, { params }).then(resp => {
      commit(SET_FRANCHISES, resp.data['hydra:member'])
    })
  },

  resetFranchises ({ commit }) {
    commit(SET_FRANCHISES, [])
  },

  getSscNotes ({ commit }, params) {
    return HTTP.get(constants.API_URI_SSC_NOTES, { params }).then(resp => {
      commit(SET_SSC_NOTES, resp.data['hydra:member'])
    })
  },

  postSscNote ({ commit }, params) {
    return HTTP.post(constants.API_URI_SSC_NOTES, params).then(resp => {
      commit(ADD_SSC_NOTE, resp.data)
    })
  },

  putSscNote ({ commit }, params) {
    return HTTP.put(constants.API_URI_SSC_NOTES + '/' + params.id, params).then(resp => {
      // @TODO
    })
  },

  resetSscNotes ({ commit }, params) {
    commit(SET_SSC_NOTES, [])
  },

  // helper actions
  getFranchiseDirectors ({ commit }, params) {
    return HTTP.get(constants.API_URI_FRANCHISE_DIRECTORS, { params }).then(resp => {
      commit(SET_FRANCHISES, resp.data['hydra:member'])
      return resp.data['hydra:member']
    })
  },

  // client lead
  getClientLead ({ commit }, params) {
    dispatch('getLead', params)
  },

  // client leads
  getClientLeads ({ commit }, params) {
    dispatch('getLeads', params)
  },

  // service groups
  getFranchiseServiceGroups ({ commit }, franchiseCode) {
    return HTTP.get(constants.API_URI_FRANCHISE_SERVICE_GROUPS, {
      params: { franchiseCode }
    }).then(resp => {
      commit(SET_FRANCHISE_SERVICE_GROUPS, resp.data['hydra:member'])
    })
  },

  resetFranchiseServiceGroups ({ commit }) {
    commit(SET_FRANCHISE_SERVICE_GROUPS, [])
  },

  // service group data
  // @TODO delete - obtained from getFranchiseServiceGroups
  getServiceGroupData ({ commit }, serviceGroupId) {
  },

  getGroupServices ({ commit }, params) {
  },

  getActiveFranchiseGroupServices ({ commit }, params) {
    return HTTP.get(constants.API_URI_FRANCHISE_SERVICES, { params }).then(resp => {
      commit(SET_ACTIVE_GROUP_SERVICES, resp.data['hydra:member'])
    })
  },

  resetActiveFranchiseGroupServices ({ commit }, params) {
    return HTTP.get(constants.API_URI_FRANCHISE_SERVICES, { params }).then(resp => {
      commit(SET_ACTIVE_GROUP_SERVICES, [])
    })
  },

  getSchools ({ commit }, franchiseCode) {
    // @TODO
    commit(SET_SCHOOLS, [])
  },

  getFranchiseMarketing ({ commit }, franchiseCode) {
    return HTTP.get(constants.API_URI_FRANCHISE_MARKETING, {
      params: {
        franchiseCode,
        archived: false,
        active: true,
        pagination: false
      }
    }).then(resp => {
      commit(SET_FRANCHISE_MARKETING, resp.data['hydra:member'])
      return resp.data['hydra:member']
    })
  },

  resetFranchiseMarketing ({ commit }) {
    commit(SET_FRANCHISE_MARKETING, [])
  },

  getGlobalMarketing ({ dispatch, commit }) {
    const globalFranchiseCode = '0'
    return HTTP.get(constants.API_URI_FRANCHISE_MARKETING, {
      params: {
        franchiseCode: globalFranchiseCode,
        archived: false,
        active: true,
        pagination: false
      }
    }).then(resp => {
      commit(SET_GLOBAL_MARKETING, resp.data['hydra:member'])
      return resp.data['hydra:member']
    })
  },

  resetGlobalMarketing () {
    commit(SET_FRANCHISE_MARKETING, [])
  },

  getFranchiseStaff ({ commit }, params) {
    return HTTP.get(constants.API_URI_FRANCHISE_EMPLOYEES, { params }).then(resp => {
      commit(SET_FRANCHISE_STAFF, resp.data['hydra:member'])
    })
  },

  getStaffEvents ({ commit }, params) {
    return HTTP.get(constants.API_URI_CALENDAR_ENTRIES, { params }).then(resp => {
      commit(SET_CALENDAR_ENTRIES, resp.data['hydra:member'])
    })
  },

  // duplicate of ../phones/getFranchisePhones
  // getFranchisePhones ({ commit }, franchiseCode) {
  //   return HTTP.get(constants.API_URI_PHONES, { params: {
  //     source: 'gt_franchise',
  //     pk: franchiseCode
  //   } }).then(resp => {
  //     commit(SET_FRANCHISE_PHONES, resp.data['hydra:member'])
  //     return resp.data['hydra:member']
  //   })
  // },

  // @TODO delete - use appointments/getAppoinements
  getFranchiseJobs ({ commit }, params) {
  },

  // @TODO delete - use appointments/getAppoinements
  getAppointments ({ commit }, params) {
    return HTTP.get(constants.API_URI_CLIENT_APPOINTMENTS, { params }).then(resp => {
      return commit(SET_JOBS, resp.data['hydra:member'])
    })
  },

  // @TODO delete - use appointments/resetAppointments
  resetAppointments ({ commit }) {
    // commit('setAppointments', [])
  },

  createAppointment ({ commit }, apptData) {
    dispatch('postAppointment', apptData)
  },

  postAppointment ({ commit }, params) {
    HTTP.post(constants.API_URI_JOBS, { params }).then(resp => {
      commit(ADD_JOB, resp.data)
    })
  },

  updateAppointment ({ commit }, apptData) {
    dispatch('putAppointment', apptData)
  },

  putAppointment ({ commit }, params) {
    HTTP.put(constants.API_URI_JOBS, { params }).then(resp => {
      // @TODO
    })
  },
}

// mutations
const mutations = {
  [SET_ACTIVE_FRANCHISE] (state, data) {
    state.activeFranchise = data
  },
  [SET_FRANCHISES] (state, data) {
    state.franchises = data
  },
  // singular franchise
  [SET_FRANCHISE] (state, data) {
    state.franchise = data
    // state.franchise.phones = []
    state.franchise.schools = []
    state.franchise.serviceGroups = []
    state.franchise.marketing = []
  },
  [SET_FRANCHISE_SETTINGS] (state, data) {
    state.franchise.settings = data
  },
  [SET_SCHOOLS] (state, data) {
    state.franchise.schools = data
  },
  [SET_FRANCHISE_PHONES] (state, data) {
    state.franchise.phones = data
  },
  [SET_FRANCHISE_MARKETING] (state, data) {
    state.franchise.marketing = data
  },
  [SET_FRANCHISE_SERVICE_GROUPS] (state, data) {
    state.franchise.serviceGroups = data
  },
  [SET_ACTIVE_GROUP_SERVICES] (state, data) {
    state.activeFranchiseGroupServices = data
  },

  // other
  [SET_GLOBAL_MARKETING] (state, data) {
    state.marketingGlobal = data
  },
  [SET_SSC_NOTES] (state, data) {
    state.sscNotes = data
  },
  [ADD_SSC_NOTE] (state, data) {
    state.sscNotes.push(data)
  },
  [SET_CLIENT_LEADS] (state, data) {
    state.clientLeads = data
  },
  [SET_SERVICE_GROUPS] (state, data) {
    state.serviceGroups = data
  },
  [SET_SERVICE_GROUP_DATA] (state, data) {
    state.serviceGroupData = data
  },
  [SET_GROUP_SERVICES] (state, data) {
    state.groupServices = data
  },
  [SET_DEFAULT_CONSULT_HOURS] (state, data) {
    state.defaultConsultHours = data
  },
  [SET_FRANCHISE_STAFF] (state, data) {
    state.franchiseStaff = data
  },
  [SET_STAFF_EVENTS] (state, data) {
    state.staffEvents = data
  },
  [SET_JOBS] (state, data) {
    state.jobs = data
  },
  [SET_VIEW_FRANCHISE_CODE] (state, code) {
    state.viewFranchiseCode = code
  },
  [SET_VIEW_FRANCHISE_CODE_NORESET] (state, code) {
    state.viewFranchiseCodeNoReset = code
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
