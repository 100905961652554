<template>
  <imask-input
    class="form-control"
    :value="value"
    :name="name"
    :id="id"
    :mask="mask"
    :placeholder="placeholder"
    @input="$emit('input', $event)"
  />
</template>
<script>
import { IMaskComponent } from 'vue-imask'

export default {
  name: 'BaseFormPhone',
  components: { 'imask-input': IMaskComponent },
  props: {
    value: {
      type: String,
      default: () => null
    },
    mask: {
      type: String,
      default: () => '(000) 000-0000'
    },
    name: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: () => null
    },
    required: {
      type: Boolean,
      default: false
    },
  }
}
</script>