// https://medium.com/i18n-and-l10n-resources-for-developers/the-ultimate-guide-to-vue-localization-with-vue-i18n-bf98b1d40c65
import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import getBrowserLocale from '@/util/i18n/get-browser-locale'
// import { supportedLocalesInclude } from '@/util/i18n/supported-locales'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context(
    "@/locals",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// function getStartingLocale() {
//   const browserLocale = getBrowserLocale({ countryCodeOnly: true })

//   if (supportedLocalesInclude(browserLocale)) {
//     return browserLocale
//   } else {
//     return process.env.VUE_APP_I18N_LOCALE || "en"
//   }
// }

export default new VueI18n({
    locale: localStorage.getItem('locale') || 'en', // getStartingLocale()
    silentTranslationWarn: true, // process.env.NODE_ENV === 'production',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
})
