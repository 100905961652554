/* eslint-disable */
import {
  SET_RESULTS
} from '../mutation-types'

// initial state
const state = {
  results: []
}

// getters
const getters = {
  searchResults: state => state.results
}

// actions
const actions = {
  async systemSearch ({ rootState, commit, dispatch }, params) {
    let query = {
      pagination: false
    }
    // console.log('systemSearch', params)
    switch (params.search) {

      // search clients
      case 'clients':
        if (rootState.currentFranchise.currentFranchise.id) {
          query.franchiseCode = rootState.currentFranchise.currentFranchiseCode
        }
        const clientText = []
        if (!isNaN(params.query)) {
          clientText.push(params.query)
        } else {
          clientText.push(...params.query.split(' '))
        }
        if (clientText.length < 2) {
          if (!isNaN(clientText[0])) {
            query['client.id'] = clientText[0]
          } else {
            query.search_name = clientText[0]
          }
        } else {
          query['client.fname'] = clientText[0]
          query['client.lname'] = clientText[1]
        }
        await dispatch('getFranchiseClients', query)
        commit(SET_RESULTS, rootState.client.franchiseClients)
        break

      // search phones
      case 'client_phones':
        query = {
          source: 'gt_client',
          hash: params.query
        }
        const phones = await dispatch('getPhones', query)
        if (phones.length) {
          query['client.id'] = phones.map(e => e.pk)
          await dispatch('getFranchiseClients', query)
          commit(SET_RESULTS, rootState.client.franchiseClients)
        }
        break

      // search leads
      case 'leads':
        const leadText = params.query.split(' ')
        query = {
          fname: leadText[0],
          lname: leadText[1] || null
        }
        await dispatch('getLeads', query)
        commit(SET_RESULTS, rootState.lead.leads)
        break

      // search postal codes
      case 'postal_codes':
        query.zipCode = params.query
        await dispatch('getZipCodes', query)
        commit(SET_RESULTS, rootState.zipCode.zipCodes)
        break

      // search franchises
      case 'franchises': // @TODO ?change to franchise_directors
        query['director.active'] = 1
        query.search_cu = params.query
        query.groups = [ 'fc_user' ]
        if ('active' in params) {
          query['franchise.active'] = params.active
        }
        await dispatch('getFranchiseDirectors', query)
        commit(SET_RESULTS, rootState.franchise.franchises)
        break

      // search appointments
      case 'appointments':
        query.id = params.query
        await dispatch('getClientAppointments', query)
        commit(SET_RESULTS, rootState.appointment.clientAppointments)
        break

      case 'appointment':
        await dispatch('getClientAppointment', params.query)
        commit(SET_RESULTS, rootState.appointment.clientAppointment)
        break

      default:
        console.error('unsupported search type:', params.search)
    }
  }
}

// mutations
const mutations = {
  [SET_RESULTS] (state, results) {
    state.results = results
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
