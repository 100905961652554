<template>
    <b-list-group-item class="flex-column align-items-start" style="overflow: hidden" button>
      <div class="d-flex w-100 justify-content-between">
        <h6 v-html="item.name" class="m-0"></h6>
        <span v-html="item.description"></span>
      </div>
    </b-list-group-item>
</template>

<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true }
  }
}
</script>
