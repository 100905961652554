<template>
  <b-btn @click="doPrint" :class="btnClass">{{ text }}</b-btn>
</template>

<script>
export default {
  name: 'BaseBtnPrint',
  props: {
    btnClass: {
      type: String,
      default () {
        return 'mt-4 float-right d-print-none'
      }
    },
    text: {
      type: String,
      default () {
        return 'Print'
      }
    }
  },
  methods: {
    doPrint () {
      this.$nextTick(() => {
        window.print()
      })
    }
  }
}
</script>
