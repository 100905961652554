import axios from 'axios'
import store from '../store'
import { constants } from '@/shared/constants'
import ErrorService from '@/services/error-service'
export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL || constants.API_URL
})

// const _vm = new Vue.extend({})

// HTTP.CancelToken = HTTP.CancelToken
// HTTP.isCancel = HTTP.isCancel

HTTP.interceptors.request.use(config => {
  store.commit('SET_LOADING')
  config.start = Date.now()

  let token = localStorage.getItem(constants.LOCAL_STORAGE_TOKEN_NAME)

  if (token && !(config.headers && config.headers['Authorization'])) {
    config.headers['Authorization'] = `Bearer ${ token }`
  }
  
  if (!(config.headers && config.headers['Accept'])) {
    config.headers['Accept'] = 'application/ld+json'
  }

  return config
}, (error) => {
  store.commit('UNSET_LOADING')
  ErrorService.onError(error)
  return Promise.reject(error)
})

HTTP.interceptors.response.use(response => {
  store.commit('UNSET_LOADING')
  return response
}, (error) => {
  store.commit('UNSET_LOADING')
  const originalRequest = error.config

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true
    // @TODO token refresh
  } else if (error.response.status === 401) {
    window.location = '/'
  }
  
  ErrorService.onError(error)
	return Promise.reject(error)
})
