<template>
  <b-form-input
    :value="value"
    :placeholder="placeholder"
    :required="required"
    @input="$emit('input', $event)"
    @blur="onBlur"
  ></b-form-input>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'BaseFormPostalCode',
  props: {
    value: {
      type: String,
      default: () => null
    },
    placeholder: {
      type: String,
      default: () => null
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters([
      'zipCodeData'
    ])
  },
  methods: {
    ...mapActions([
      'getZipCodeData'
    ]),
    async onBlur () {
      await this.getZipCodeData(this.value)
      this.$emit('pc-data', this.zipCodeData)
    }
  }
}
</script>