<template>
  <b-form-select
    :value="value"
    :options="countryList"
    id="country"
    ref="selectCountry"
    :required="required"
    @change="$emit('input', $event)"
    >
    <template v-if="useLabel" #first>
      <b-form-select-option :value="null">{{ $t('country') }}</b-form-select-option>
    </template>
  </b-form-select>
</template>
<script>
// import states from '@/shared/country_states'

export default {
  name: 'BaseFormSelectCountry',
  props: {
    value: {
      type: [Number, String],
      default: () => 'US'
    },
    required: {
      type: Boolean,
      default: false
    },
    countries: {
      type: Object,
      default: () => ({
        US: 'United States',
        CA: 'Canada',
        TR: 'Turkey'
      })
    },
    useLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    countryList () {
      let res = []
      for (let k in this.countries) {
        let item = {
          value: k,
          text: this.countries[k]
        }
        res.push(item)
      }
      return res
    }
  }
}
</script>