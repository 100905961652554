// client.js
export const SET_CLIENTS = 'SET_CLIENTS'
export const ADD_CLIENT = 'ADD_CLIENT'
export const SET_FRANCHISE_CLIENTS = 'SET_FRANCHISE_CLIENTS'
export const ADD_FRANCHISE_CLIENT = 'ADD_FRANCHISE_CLIENT'
export const SET_CLIENTS_TOTAL_UNFILTERED = 'SET_CLIENTS_TOTAL_UNFILTERED'
export const SET_CLIENT = 'SET_CLIENT'
export const SET_CLIENT_STATUS_OPTIONS = 'SET_CLIENT_STATUS_OPTIONS'
export const ADD_CLIENT_PARENT = 'ADD_CLIENT_PARENT'
export const SET_FRANCHISE_SUBSCTIPTIONS = 'SET_FRANCHISE_SUBSCTIPTIONS'
export const ADD_FRANCHISE_SUBSCTIPTION = 'ADD_FRANCHISE_SUBSCTIPTION'
// lead.js
export const SET_LEADS = 'SET_LEADS'
export const SET_LEADS_TOTAL_UNFILTERED = 'SET_LEADS_TOTAL_UNFILTERED'
export const UPDATE_LEAD = 'UPDATE_LEAD'
export const SET_LEAD = 'SET_LEAD'
export const SET_LEAD_STATUS_OPTIONS = 'SET_LEAD_STATUS_OPTIONS'
export const SET_LEAD_NOTES = 'SET_LEAD_NOTES'
export const ADD_LEAD_NOTE = 'ADD_LEAD_NOTE'
// drip campaigns
export const SET_DRIP_CAMPAIGN_OPTIONS = 'SET_DRIP_CAMPAIGN_OPTIONS'
// franchise.js
export const SET_ACTIVE_FRANCHISE = 'SET_ACTIVE_FRANCHISE'
export const SET_FRANCHISE_DIRECTORY = 'SET_FRANCHISE_DIRECTORY'
export const SET_FRANCHISE = 'SET_FRANCHISE'
export const SET_FRANCHISES = 'SET_FRANCHISES'
export const SET_FRANCHISE_SETTINGS = 'SET_FRANCHISE_SETTINGS'
export const SET_FRANCHISE_PHONES = 'SET_FRANCHISE_PHONES'
export const SET_FRANCHISE_MARKETING = 'SET_FRANCHISE_MARKETING'
export const SET_GLOBAL_MARKETING = 'SET_GLOBAL_MARKETING'
export const SET_FRANCHISE_STAFF = 'SET_FRANCHISE_STAFF'
export const SET_FRANCHISE_SERVICE_GROUPS = 'SET_SERVICE_GROUPS'
export const SET_VIEW_FRANCHISE_CODE = 'SET_VIEW_FRANCHISE_CODE'
export const SET_VIEW_FRANCHISE_CODE_NORESET = 'SET_VIEW_FRANCHISE_CODE_NORESET'
export const SET_SSC_NOTES = 'SET_SSC_NOTES'
export const ADD_SSC_NOTE = 'ADD_SSC_NOTE'

export const SET_JOBS = 'SET_JOBS'

export const SET_FRANCHISE_SCHOOLS = 'SET_FRANCHISE_SCHOOLS'
export const SET_CLIENT_CONTACTS = 'SET_CLIENT_CONTACTS'
export const SET_GROUP_CONTACTS = 'SET_GROUP_CONTACTS'
export const ADD_FORM_SUBMISSION = 'ADD_FORM_SUBMISSION'
export const SET_FORM_SUBMISSIONS = 'SET_FORM_SUBMISSIONS'

export const SET_CLIENT_LEADS = 'SET_CLIENT_LEADS'
export const SET_SERVICE_GROUPS = 'SET_SERVICE_GROUPS'
export const SET_SERVICE_GROUP_DATA = 'SET_SERVICE_GROUP_DATA'
export const SET_GROUP_SERVICES = 'SET_GROUP_SERVICES'
export const SET_ACTIVE_GROUP_SERVICES = 'SET_ACTIVE_GROUP_SERVICES'
export const SET_SCHOOLS = 'SET_SCHOOLS'
export const SET_DEFAULT_CONSULT_HOURS = 'SET_DEFAULT_CONSULT_HOURS'
export const ADD_CLIENT_APPOINTMENT = 'ADD_CLIENT_APPOINTMENT'
export const SET_CLIENT_APPOINTMENTS = 'SET_CLIENT_APPOINTMENTS'
export const SET_CLIENT_APPOINTMENT = 'SET_CLIENT_APPOINTMENT'
// temp?
export const SET_APPOINTMENT = 'SET_APPOINTMENT'
export const SET_STAFF_EVENTS = 'SET_STAFF_EVENTS'

export const SET_CALENDAR_ENTRIES = 'SET_CALENDAR_ENTRIES'

// currentUser module
export const SET_CURRENT_ERROR = 'SET_CURRENT_ERROR'
export const SET_CURRENT_USER_TOKEN = 'SET_CURRENT_USER_TOKEN'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_CURRENT_USER_PROFILE = 'SET_CURRENT_USER_PROFILE'
export const LOGOUT = 'LOGOUT'

// currentFranchise module
export const SET_USER_FRANCHISE_LIST = 'SET_USER_FRANCHISE_LIST'
export const SET_CURRENT_FRANCHISE = 'SET_CURRENT_FRANCHISE'

// group.js
export const SET_GROUP = 'SET_GROUP'
export const SET_GROUPS = 'SET_GROUPS'
export const ADD_GROUP = 'ADD_GROUP'

// user.js
export const SET_USER_STATUS_OPTIONS = 'SET_USER_STATUS_OPTIONS'
export const SET_USER = 'SET_USER'
export const SET_USERS = 'SET_USERS'
export const ADD_USER = 'ADD_USER'
export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const SET_USER_PROFILE_ID = 'SET_USER_PROFILE_ID'

export const SET_SSC_REP = 'SET_SSC_REP'
export const SET_SSC_REPS = 'SET_SSC_REPS'
export const ADD_SSC_REP = 'ADD_SSC_REP'

// global.js
export const SET_LOADING = 'SET_LOADING'
export const UNSET_LOADING = 'UNSET_LOADING'
export const ACTION_PENDING = 'ACTION_PENDING'
export const ACTION_COMPLETE = 'ACTION_COMPLETE'
export const ACTION_COMPLETE_ALL = 'ACTION_COMPLETE_ALL'
export const ACTION_ERROR = 'ACTION_ERROR'
export const ACTION_CLEAR_ERROR = 'ACTION_CLEAR_ERROR'
export const SET_STATES_LIST = 'SET_STATES_LIST'

// modal.js
export const ADD_MODAL = 'ADD_MODAL'
export const REMOVE_MODAL = 'REMOVE_MODAL'
export const SET_MODAL_GROUPS_DATA = 'SET_MODAL_GROUPS_DATA'

// phone.js
export const SET_PHONES = 'SET_PHONES'

// pcmap.js
export const SET_MAP_CENTER = 'SET_MAP_CENTER'
export const SET_CLOSEST_TERRITORIES = 'SET_CLOSEST_TERRITORIES'
export const SET_TERRITORY_COORDS = 'SET_TERRITORY_COORDS'

// search.js
export const SET_RESULTS = 'SET_RESULTS'

export const SET_ZIP_CODES = 'SET_ZIP_CODES'
export const SET_ZIP_CODE_DATA = 'SET_ZIP_CODE_DATA'
