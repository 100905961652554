/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import {
  SET_MAP_CENTER,
  SET_CLOSEST_TERRITORIES,
  SET_TERRITORY_COORDS
} from '../mutation-types'

// initial state
const state = {
  mapCenter: null,
  closestTerritories: [],
  territoryCoords: null
}

// getters
const getters = {
  mapCenter: state => state.mapCenter,
  closestTerritories: state => state.closestTerritories,
  territoryCoords: state => state.territoryCoords
}

// actions
const actions = {
  getClosestTerritories ({ commit }, params) {
    // @TODO HTTP.get('zip_codes/closest', params).then((resp) => {
      return HTTP.get('https://dashboard.clubztutoring.com/franchise/api/closest/' + params.lat, params).then((resp) => {
      if (resp.data.status === 'success') {
        commit(SET_MAP_CENTER, resp.data.center)
        commit(SET_CLOSEST_TERRITORIES, resp.data.data)
      }
    })
  },
  resetClosestTerritories ({ commit }) {
    commit(SET_MAP_CENTER, null)
    commit(SET_CLOSEST_TERRITORIES, [])
  },
  getTerritoryCoords ({ commit }, params) {
    // @TODO HTTP.get('zip_codes/coords', params).then((resp) => {
    return HTTP.post('https://dashboard.clubztutoring.com/gmap/api/get_coords_test', params).then((resp) => {
      if (resp.data.status === 'success') {
        commit(SET_TERRITORY_COORDS, resp.data.data)
      }
    })
  },
  resetTerritoryCoords ({ commit }) {
    commit(SET_TERRITORY_COORDS, null)
  }
}

// mutations
const mutations = {
  [SET_MAP_CENTER] (state, mapCenter) {
    state.mapCenter = mapCenter
  },
  [SET_CLOSEST_TERRITORIES] (state, closestTerritories) {
    state.closestTerritories = closestTerritories
  },
  [SET_TERRITORY_COORDS] (state, territoryCoords) {
    state.territoryCoords = territoryCoords
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
