<template>
  <b-row class="m-0">
    <b-col v-if="buttons.cancel" cols="5" class="p-0">
      <b-button
        type="button"
        :size="size"
        variant="danger"
        @click="$emit('form-button-cancel')"
        v-b-tooltip.hover.top="tooltipCancel"
        :block="block"
        ><i class="fa fa-times" aria-hidden="true"></i> {{ buttonLabel.cancel }}</b-button
      >
    </b-col>
    <b-col class="p-0 text-right">
      <b-button
        v-if="buttons.reset"
        type="button"
        class="mr-2"
        :size="size"
        variant="warning"
        @click="$emit('form-button-reset')"
        v-b-tooltip.hover.top="tooltipReset"
        :block="block"
        ><i class="fa fa-sync" aria-hidden="true"></i> {{ buttonLabel.reset }}</b-button
      >
      <b-button
        v-if="buttons.save"
        type="button"
        :size="size"
        variant="success"
        @click="$emit('form-button-submit')"
        v-b-tooltip.hover.top="tooltipSave"
        :block="block"
        ><i class="fa fa-check" aria-hidden="true"></i> {{ buttonLabel.save }}</b-button
      >
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'BaseFormButtons',
  props: {
    size: {
      type: String,
      default: 'sm',
    },
    updating: {
      type: Boolean,
      default: false,
    },
    noReset: {
      type: Boolean,
      default: false,
    },
    noCancel: {
      type: Boolean,
      default: false,
    },
    labelSave: {
      type: String,
      default: null,
    },
    labelUpdate: {
      type: String,
      default: null,
    },
    labelReset: {
      type: String,
      default: null,
    },
    labelCancel: {
      type: String,
      default: null,
    },
    tooltipCancel: {
      type: String,
      default: null,
    },
    tooltipReset: {
      type: String,
      default: null,
    },
    tooltipSave: {
      type: String,
      default: null,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttons() {
      return {
        cancel: !this.noCancel,
        reset: !this.noReset,
        save: true,
      }
    },
    buttonLabel() {
      return {
        cancel: this.labelCancel || this.$t('label.cancel'),
        reset: this.labelReset || this.$t('label.reset'),
        save: this.updating
          ? this.labelUpdate || this.$t('label.update')
          : this.labelSave || this.$t('label.save'),
      }
    },
  },
}
</script>
