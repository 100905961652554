/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import {
  SET_FRANCHISE_SCHOOLS,
  SET_CLIENT_CONTACTS,
  SET_GROUP_CONTACTS,
  SET_FORM_SUBMISSIONS,
  ADD_FORM_SUBMISSION
} from '../mutation-types'

function setFormData (data) {
  const submissions = data.map(e => {
    let data = { id: e.id }
    let udata = {}
    for (const i in e.values) {
      data[e.values[i].field.name] = e.values[i].value
      // update data: sets field ID with submission ID
      udata[e.values[i].field.id] = e.values[i].id
    }
    return {
      id: e.id,
      user: e.user,
      data,
      udata
    }
  })
  submissions.sort((a,b) => (a.data.name > b.data.name) ? 1 : ((b.data.name > a.data.name) ? -1 : 0))
  return submissions
}

// IDs of client contact fields
const FORM_CLIENT_CONTACT_FIELDS = {
  address: 9,
  address2: 10,
  city: 11,
  country: 14,
  email: 24,
  fname: 7,
  franchise_code: 3,
  lname: 8,
  notes: 15,
  primary: 23,
  rel: 4,
  rel_pid: constants.FORM_ID_CONTACT_CLIENT_FIELD_ID, // client ID OR
  group_pk: constants.FORM_ID_CONTACT_GROUP_FIELD_ID, // group ID
  relationship: 6,
  state: 12,
  zip_code: 13
}

// initial state
const state = {
  franchiseSchools: [],
  clientContacts: [],
  groupContacts: []
}

// getters
const getters = {
  franchiseSchools: state => state.franchiseSchools,
  clientContacts: state => {
    return state.groupContacts.length 
    ? state.groupContacts : state.clientContacts // @XXX
  },
  groupContacts: state => state.groupContacts
}

// actions
const actions = {

  // franchiseSchools //////////////////////////////////////

  async getFranchiseSchools({ commit }, franchiseCode) {
    // get for submissions for schools associated with the franchise
    const resp = await HTTP.get(constants.API_URI_FORM_SUBMISSIONS, {
      params: {
        'values.form': constants.FORM_ID_SCHOOL,
        'values.field': constants.FORM_ID_SCHOOL_FC_FIELD_ID,
        'values.value': franchiseCode
      }
    })

    const submissions = resp.data['hydra:member'].map(e => {
      let data = {}
      for (const i in e.values) {
        data[e.values[i].field.name] = e.values[i].value
      }
      return {
        id: e.id,
        user: e.user,
        data: data
      }
    })

    submissions.sort((a,b) => (a.data.name > b.data.name) ? 1 : ((b.data.name > a.data.name) ? -1 : 0))
    
    commit(SET_FRANCHISE_SCHOOLS, submissions)
    return resp
  },

  resetFranchiseSchools ({ commit }) {
    commit(SET_FRANCHISE_SCHOOLS, [])
  },

  // clientContacts //////////////////////////////////////
  async getContactRecord({ commit }, id) {
    // get form submissions associated with the id
    const params = {
      submission: id
    }
    const getClientContact = await HTTP.get(constants.API_URI_FORM_SUBMISSIONS, { params })
    .then(async resp => {
      if (!resp.length) {
        return {}
      }
      const submissions = setFormData(resp.data['hydra:member'])
  
      // get phone data
      await HTTP.get(constants.API_URI_PHONES, { params: {
        source: 'gt_i_contacts',
        pk: submissions[0].id
      }}).then(resp2 => {
        submissions[0].phones = resp2.data['hydra:member']
      })

      commit(SET_CLIENT_CONTACTS, [submissions])
      return submission
    })

    return getClientContact
  },

  async getContactRecords({ dispatch, state }, { clientId, groupId }) {
    let getContacts
    if (groupId) {
      getContacts = await dispatch('getGroupContacts', groupId)
    }
    if (!(groupId || state.groupContacts.length) && clientId) {
      getContacts = await dispatch('getClientContacts', clientId)
    }

    return getContacts
  },

  resetContactRecords ({ commit }) {
    commit(SET_GROUP_CONTACTS, [])
    commit(SET_CLIENT_CONTACTS, [])
  },

  // @XXX issue: may contain many contacts for the same client
  async getClientContacts({ commit }, clientId) {
    // get form submissions for contacts associated with the client
    const params = {
      'values.form': constants.FORM_ID_CONTACT,
      'values.field': constants.FORM_ID_CONTACT_CLIENT_FIELD_ID,
      'values.value': clientId,
      itemsPerPage: 1
    }
    const getClientContacts = await HTTP.get(constants.API_URI_FORM_SUBMISSIONS, { params })
    .then(async resp => {
      const submissions = setFormData(resp.data['hydra:member'])
  
      // get phone data
      for (let i in submissions) {
        if (i > 0) { continue } // @XXX temp fix
        await HTTP.get(constants.API_URI_PHONES, { params: {
          source: 'gt_i_contacts',
          pk: submissions[i].id
        } }).then(resp2 => {
          submissions[i].phones = resp2.data['hydra:member']
        })
      }
      
      commit(SET_CLIENT_CONTACTS, submissions)
      return submissions
    })

    return getClientContacts
  },

  // groupContacts //////////////////////////////////////
  // @XXX issue: may contain many contacts for the same client
  async getGroupContacts({ commit }, groupId) {
    // get for submissions for contacts associated with the group
    const params = {
      'values.form': constants.FORM_ID_CONTACT,
      'values.field': constants.FORM_ID_CONTACT_GROUP_FIELD_ID,
      'values.value': groupId
    }
    const getGroupContacts = await HTTP.get(constants.API_URI_FORM_SUBMISSIONS, { params })
    .then(async resp => {
      const submissions = setFormData(resp.data['hydra:member'])
  
      // get phone data
      for (let i in submissions) {
        if (i > 0) { continue } // @XXX temp fix 
        await HTTP.get(constants.API_URI_PHONES, { params: {
          source: 'gt_i_contacts',
          pk: submissions[i].id
        } }).then(resp2 => {
          submissions[i].phones = resp2.data['hydra:member']
        })
      }
      
      commit(SET_GROUP_CONTACTS, submissions)
    })

    return getGroupContacts
  },

  async postClientContact ({ dispatch }, payload) {
    if (!(payload.rel_pid)) {
      return Promise.reject('payload must include rel_pid')
    }
    return dispatch('saveContact', { payload, method: 'post' })
  },

  async putClientContact ({ dispatch }, payload) {
    return dispatch('saveContact', { payload, method: 'put' })
  },

  async postGroupContact ({ dispatch }, payload) {
    if (!(payload.group_pk)) {
      return Promise.reject('payload must include group_pk')
    }
    return dispatch('saveContact', { payload, method: 'post' })
  },

  async putGroupContact ({ dispatch }, payload) {
    const id = payload.id
    delete payload.id
    return dispatch('saveContact', { payload, method: 'put',  id})
  },

  async saveContact({ rootState }, { payload, method, id }) {
    if (!(payload.rel_pid || payload.group_pk)) {
      return Promise.reject('payload must include rel_pid (client) or group_pk (group)')
    }

    if (!(payload.fname || payload.lname)) {
      return Promise.reject('payload must include at least a first or last name')
    }

    if (!['post','put','patch'].includes(method)) {
      return Promise.reject('method passed is invalid. Must be post, put or patch.')
    }

    let submission = {}
    let submissionFieldValue
    // db form fields and associated IDs
    // @TODO initialize from import
    const formFieldIds = FORM_CLIENT_CONTACT_FIELDS
    // desired default values
    const formFieldDefaults = {
      rel: !payload.group_pk ? 'gt_client' : 'gt_groups',
      primary: 1
    }
    // merged payload
    payload = Object.assign({}, formFieldDefaults, payload)
    // phone data
    const phoneFields = ['home','mobile','work']
    const phoneHashRegex = /[^0-9]/g
    const phoneSource = 'gt_i_contacts'
    const phonePrimaryDefault = 'mobile'

    if (method === 'post') {
      // post submission
      submission = await HTTP.post(constants.API_URI_FORM_SUBMISSIONS, { 
        userId: rootState.currentUser.currentUser.id
      }).then((resp) => {
        // commit(ADD_FORM_SUBMISSION, resp.data)
        return resp.data
      })
    } else {
      if (!id) {
        return Promise.reject('payload must include [submission] id')
      }
      submission = {
        id
      }
    }

    // post submission values
    for (let k in formFieldIds) {
      if (method === 'post' || !payload[formFieldIds[k]]) {
        if (payload[k]) {
          submissionFieldValue = {
            form: '/api/forms/' + constants.FORM_ID_CONTACT,
            submission: '/api/form_submissions/' + submission.id,
            field: '/api/form_fields/' + formFieldIds[k],
            value: payload[k].toString()
          }
          await HTTP.post(constants.API_URI_FORM_SUBMISSION_VALUES, submissionFieldValue)
        }
      } else if (payload[k]) {
        submissionFieldValue = {
          value: payload[k].toString()
        }
        await HTTP.put(constants.API_URI_FORM_SUBMISSION_VALUES + `/${id}`, submissionFieldValue)
      }
    }

    // post phone numbers
    phoneFields.forEach(async type => {
      console.log('phoneFields', payload)
      if (payload.phones && payload.phones[type] && payload.phones[type].number) {
        let phoneData = {
          hash: payload.phones[type].number.replace(phoneHashRegex, ''),
          source: phoneSource,
          pk: submission.id,
          type: type,
          primary: (type === phonePrimaryDefault),
          number: payload.phones[type].number
        }
        await HTTP[method](constants.API_URI_PHONES, phoneData)
      }
    })

    return submission
  },

  resetClientContacts ({ commit }) {
    commit(SET_CLIENT_CONTACTS, [])
  },

  // entries //////////////////////////////////////

  getFormSubmission({ commit }, id) {
    return HTTP.get(constants.API_URI_FORM_SUBMISSIONS + '/' + id).then(resp => {
      const submission = {
        id: resp.id
      }
      for (const i in resp.values) {
        submission[resp.values[i].field.name] = resp.values[i].value
      }
      return submission
    })
  },

  getFormSubmissions({ commit, dispatch }, submissionIds) {
    return HTTP.get(constants.API_URI_FORM_SUBMISSIONS, {
      params: {
        submission: submissionIds
      }
    }).then(resp => {
      const submissions = resp.data['hydra:member'].map(e => {
        let ret = {
          id: e.id
        }
        for (const i in e.values) {
          ret[e.values[i].field.name] = e.values[i].value
        }
      })
      return submissions
    })
  }
}

// mutations
const mutations = {
  // franchiseSchools
  [SET_FRANCHISE_SCHOOLS] (state, payload) {
    state.franchiseSchools = payload
  },
  // Contacts
  [SET_CLIENT_CONTACTS] (state, payload) {
    console.info('SET_CLIENT_CONTACTS', payload)
    state.clientContacts = payload
    console.info(state.clientContacts)
  },
  [SET_GROUP_CONTACTS] (state, payload) {
    console.info('SET_GROUP_CONTACTS', payload)
    state.groupContacts = payload
    console.info(state.groupContacts)
  },
  // submissions
  [SET_FORM_SUBMISSIONS] (state, payload) {
    state.submissions = payload
  },
  [ADD_FORM_SUBMISSION] (state, payload) {
    state.submissions.push(payload)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
