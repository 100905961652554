/* eslint-disable */
import {
  ADD_MODAL,
  REMOVE_MODAL,
  SET_MODAL_GROUPS_DATA
} from '../mutation-types'

const state = {
  modals: [],
  modalGroupsData: null
}

const getters = {
    modals: state => state.modals,
    modalGroupsData: state => state.modalGroupsData
}

const actions = {
  // general modals
  addModal ({commit}, payload) {
    commit(ADD_MODAL, payload)
  },
  removeModal ({commit}, index) {
    commit(REMOVE_MODAL, index)
  },
  // user/client groups modal
  showModalGroups ({commit}, payload) {
    commit(SET_MODAL_GROUPS_DATA, payload)
  },
  hideModalGroups ({commit}) {
    commit(SET_MODAL_GROUPS_DATA, null)
  }
}

const mutations = {
  [ ADD_MODAL ] (state, payload) {
    state.modals.push(payload)
  },
  [ REMOVE_MODAL ] (state, index) {
    state.modals.splice(index, 1)
  },
  [ SET_MODAL_GROUPS_DATA ] (state, payload) {
    // @TODO validate { name: String, groups: Array of objects } || null
    state.modalGroupsData = payload
  }
}

export default {

  state,
  getters,
  actions,
  mutations
}
