/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import {
  SET_PHONES
} from '../mutation-types'

const SOURCE_CLIENT = 'gt_client'
const SOURCE_CONTACT = 'gt_i_contacts'
const SOURCE_SCHOOL = 'gt_i_schools'
const SOURCE_FRANCHISE = 'gt_franchise'

// initial state
const state = {
  phones: []
}

// getters
const getters = {
  phones: state => state.phones
}

// actions
const actions = {
  // generic
  getPhones ({ commit }, params) {
    return HTTP.get(constants.API_URI_PHONES, { params }).then(resp => {
      commit(SET_PHONES, resp.data['hydra:member'])
      return resp.data['hydra:member']
    })
  },
  getPhone (_, id) {
    return HTTP.get(constants.API_URI_PHONES + `/${id}`, { params }).then(resp => {
      return resp.data
    })
  },
  postPhone (_, payload) {
    return HTTP.post(constants.API_URI_PHONES, payload).then(resp => {
      return resp.data
    })
  },
  putPhone (_, payload) {
    return HTTP.put(constants.API_URI_PHONES + `/${payload.id}`, payload).then(resp => {
      return resp.data
    })
  },
  deletePhone (_, id) {
    return HTTP.put(constants.API_URI_PHONES + `/${id}`)
  },

  // FRANCHISE
  /**
   * get franchise phones
   * @param { Object } params {
   *   "pk": client_id,
   *   "type": "(mobile|home|work)",
   *   "primary": Boolean
   * }
   * @returns Promise Object
   */
  getFranchisePhones ({ commit }, params) {
    if (typeof params !== 'object') {
      params = { pk: params }
    }
    params.source = SOURCE_FRANCHISE
    return HTTP.get(constants.API_URI_PHONES, { params }).then(resp => {
      commit(SET_PHONES, resp.data['hydra:member'])
      return resp.data['hydra:member']
    })
  },
  /**
   * post franchise phone
   * @param { Object } payload {
   *   "pk": franchise_code,
   *   "number": "(234) 555-6789",
   *   "type": "(mobile|home|work)",
   *   "primary": Boolean
   * }
   * @returns Promise Object
   */
  postFranchisePhone ({ dispatch }, payload) {
    payload.source = SOURCE_FRANCHISE
    return dispatch('postPhone', payload).then(resp => {
      return resp.data
    })
  },
  /**
   * put franchise phone
   * @param { Object } payload {
   *   id: entry_id
   *   number: "(234) 555-6789",
   *   type: "(mobile|home|work)",
   *   primary: Boolean
   * }
   * @returns Promise Object
   */
  putFranchisePhone ({ dispatch }, payload) {
    payload.source = SOURCE_FRANCHISE
    return dispatch('putPhone', payload).then(resp => {
      return resp.data
    })
  },

  // CLIENT
  /**
   * get client phones
   * @param { Object } params {
   *   "pk": client_id,
   *   "type": "(mobile|home|work)",
   *   "primary": Boolean
   * }
   * @returns Promise Object
   */
  getClientPhones ({ commit }, params) {
    params.source = SOURCE_CLIENT
    return HTTP.get(constants.API_URI_PHONES, { params }).then(resp => {
      commit(SET_PHONES, resp.data['hydra:member'])
      return resp.data['hydra:member']
    })
  },
  /**
   * post client phone
   * @param { Object } payload {
   *   "pk": client_id,
   *   "number": "(234) 555-6789",
   *   "type": "(mobile|home|work)",
   *   "primary": Boolean
   * }
   * @returns Promise Object
   */
  postClientPhone ({ dispatch }, payload) {
    payload.source = SOURCE_CLIENT
    return dispatch('postPhone', payload).then(resp => {
      return resp.data
    })
  },
  /**
   * put client phone
   * @param { Object } payload {
   *   id: entry_id
   *   number: "(234) 555-6789",
   *   type: "(mobile|home|work)",
   *   primary: Boolean
   * }
   * @returns Promise Object
   */
  putClientPhone ({ dispatch }, payload) {
    payload.source = SOURCE_CLIENT
    return dispatch('putPhone', payload).then(resp => {
      return resp.data
    })
  },

  // CLIENT/GROUP CONTACT
  /**
   * get contact phones
   * @param { Object } params {
   *   "pk": contact_id,
   *   "type": "(mobile|home|work)",
   *   "primary": Boolean
   * }
   * @returns Promise Object
   */
  getContactPhones ({ commit }, params) {
    params.source = SOURCE_CONTACT
    return HTTP.get(constants.API_URI_PHONES, { params }).then(resp => {
      commit(SET_PHONES, resp.data['hydra:member'])
      return resp.data['hydra:member']
    })
  },
  /**
   * post contact phone
   * @param { Object } payload {
   *   "pk": contact_id,
   *   "number": "(234) 555-6789",
   *   "type": "(mobile|home|work)",
   *   "primary": Boolean
   * }
   * @returns Promise Object
   */
  postContactPhone ({ dispatch }, payload) {
    payload.source = SOURCE_CONTACT
    return dispatch('postPhone', payload).then(resp => {
      return resp.data
    })
  },
  /**
   * put contact phone
   * @param { Object } payload {
   *   id: entry_id
   *   number: "(234) 555-6789",
   *   type: "(mobile|home|work)",
   *   primary: Boolean
   * }
   * @returns Promise Object
   */
  putContactPhone ({ dispatch }, payload) {
    payload.source = SOURCE_CONTACT
    return dispatch('putPhone', payload).then(resp => {
      return resp.data
    })
  },

  // SCHOOL
  /**
   * get school phones
   * @param { Object } params {
   *   "pk": contact_id,
   *   "type": "(mobile|home|work)",
   *   "primary": Boolean
   * }
   * @returns Promise Object
   */
  getSchoolPhones ({ commit }, params) {
    params.source = SOURCE_SCHOOL
    return HTTP.get(constants.API_URI_PHONES, { params }).then(resp => {
      commit(SET_PHONES, resp.data['hydra:member'])
      return resp.data['hydra:member']
    })
  },
  /**
   * post contact phone
   * @param { Object } payload {
   *   "pk": school_id,
   *   "number": "(234) 555-6789",
   *   "type": "(mobile|home|work)",
   *   "primary": Boolean
   * }
   * @returns Promise Object
   */
  postSchoolPhone ({ dispatch }, payload) {
    payload.source = SOURCE_SCHOOL
    return dispatch('postPhone', payload).then(resp => {
      return resp.data
    })
  },
  /**
   * put contact phone
   * @param { Object } payload {
   *   id: entry_id
   *   number: "(234) 555-6789",
   *   type: "(mobile|home|work)",
   *   primary: Boolean
   * }
   * @returns Promise Object
   */
  putSchoolPhone ({ dispatch }, payload) {
    payload.source = SOURCE_SCHOOL
    return dispatch('putPhone', payload).then(resp => {
      return resp.data
    })
  }
}

// mutations
const mutations = {
  [SET_PHONES] (state) {
    state.phones
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
