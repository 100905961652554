export default (store) => {
  const ability = store.getters.ability
  if (store.state.currentUser && store.state.currentUser.rules) {
    ability.update(store.state.currentUser.rules)
  }
  return store.subscribe((mutation) => {
    switch (mutation.type) {
      case 'SET_CURRENT_USER':
        // console.log('subscribe(SET_CURRENT_USER)', mutation.payload)
        ability.update(mutation.payload.rules)
        break
      case 'LOGOUT':
        // console.log('subscribe(LOGOUT)')
        ability.update([{ actions: 'read', subject: 'all' }])
        break
    }
  })
}
