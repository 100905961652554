<template>
  <div class="add-clear-to-element">
    <slot></slot>
    <span @click="$emit('clear')">&times;</span>
  </div>
</template>

<script>
export default {
  name: 'BaseFormWithClear'
}
</script>

<style scoped>
.add-clear-to-element {
  position: relative;
}
.add-clear-to-element select {
  padding-right: 45px;
}
.add-clear-to-element span {
  display: none;
}
.add-clear-to-element:hover span {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
  border: none;
  color: #768192;
  display: block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-weight: bold;
  margin: auto;
  cursor: pointer;
  transition: .1s;
}
</style>
