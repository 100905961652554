<template>
  <b-form-select
    :value="value"
    :options="statesList"
    id="state"
    ref="selectState"
    :required="required"
    @change="$emit('input', $event)"
    >
    <template v-if="!noLabel" #first>
      <b-form-select-option :value="null">{{ $t('state') }}</b-form-select-option>
    </template>
  </b-form-select>
</template>
<script>
import states from '@/shared/country_states'

export default {
  name: 'BaseFormSelectState',
  props: {
    value: {
      type: [Number, String],
      default: () => null
    },
    country: {
      type: String,
      default: () => 'US'
    },
    required: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    statesList () {
      let res = []
      if (this.country && states[this.country]) {
        let keys = Object.keys(states[this.country])
        for (let k in keys) {
          let item = {
            value: keys[k],
            text: states[this.country][keys[k]]
          }
          res.push(item)
        }
      }
      return res
    }
  }
}
</script>