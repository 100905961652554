/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import {
  SET_ZIP_CODES,
  SET_ZIP_CODE_DATA
} from '../mutation-types'

// initial state
const state = {
  zipCodes: [],
  zipCodeData: {}
}

// getters
const getters = {
  zipCodes: state => state.zipCodes,
  zipCodeData: state => state.zipCodeData
}

// actions
const actions = {
  getZipCodes ({ commit }, params) {
    return HTTP.get(constants.API_URI_ZIP_CODES, { params }).then(resp => {
      commit(SET_ZIP_CODES, resp.data['hydra:member'])
    })
  },
  getZipCodeData ({ commit }, zipCode) {
    return HTTP.get(constants.API_URI_US_ZIP_CODES, {
      params: { zipCode }
    }).then(resp => {
      commit(SET_ZIP_CODE_DATA, resp.data['hydra:member'][0])
    })
  }
}

// mutations
const mutations = {
  [SET_ZIP_CODES] (state, zipCodes) {
    state.zipCodes = zipCodes
  },
  [SET_ZIP_CODE_DATA] (state, zipCodeData) {
    state.zipCodeData = zipCodeData
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
