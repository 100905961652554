/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import {
  ADD_CLIENT_APPOINTMENT,
  SET_CLIENT_APPOINTMENTS,
  SET_CLIENT_APPOINTMENT
} from '../mutation-types'

// initial state
const state = {
  clientAppointmentTypes: {
    enrollment: constants.CLIENT_APPOINTMENT_TYPE_ENROLLMENT,
    session: constants.CLIENT_APPOINTMENT_TYPE_SESSION
  },
  clientAppointments: [],
  clientAppointment: {}
}

// getters
const getters = {
  clientAppointmentTypes: state => state.clientAppointmentTypes,
  clientAppointments: state => state.clientAppointments,
  clientAppointment: state => state.clientAppointment
}

// actions
const actions = {
  getClientAppointments ({ commit }, params) {
    return HTTP.get(constants.API_URI_CLIENT_APPOINTMENTS, { params }).then(resp => {
      commit(SET_CLIENT_APPOINTMENTS, resp.data['hydra:member'])
    })
  },

  resetClientAppointments ({ commit }, params) {
    commit(SET_CLIENT_APPOINTMENTS, [])
  },
  
  getClientAppointment ({ commit }, id) {
    return HTTP.get(constants.API_URI_CLIENT_APPOINTMENTS + '/' + id).then(resp => {
      commit(SET_CLIENT_APPOINTMENT, resp.data)
      return resp.data
    })
  },

  postClientAppointment ({ commit }, payload) {
    return HTTP.post(constants.API_URI_CLIENT_APPOINTMENTS, payload).then(resp => {
      commit(ADD_CLIENT_APPOINTMENT, resp.data['hydra:member'])
      return resp.data
    })
  },

  putClientAppointment ({ commit }, payload) {
    return HTTP.put(constants.API_URI_CLIENT_APPOINTMENTS + '/' + payload.id, payload).then(resp => {
      // @TODO commit(UPDATE_CLIENT_APPOINTMENT, resp.data)
      return resp.data
    })
  },

  resetClientAppointment ({ commit }, params) {
    commit(SET_CLIENT_APPOINTMENT, {})
  },

  postClientAppointmentSignature ({ commit }, payload) {
    return HTTP.post(constants.API_URI_CLIENT_APPOINTMENT_CONFIRMATINS, payload).then(resp => {
      // @TODO commit(UPDATE_CLIENT_APPOINTMENT, resp.data)
      return resp.data
    })
  }
}

// mutations
const mutations = {
  [SET_CLIENT_APPOINTMENTS] (state, data) {
    state.clientAppointments = data
  },
  [ADD_CLIENT_APPOINTMENT] (state, data) {
    state.clientAppointments.push(data)
  },
  [SET_CLIENT_APPOINTMENT] (state, data) {
    state.clientAppointment = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
