<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="selected"
    id="client-query"
    :auto-select-one-item="autoSelectOneItem"
    :min-len="minLen"
    :items="searchOptions"
    :get-label="(e) => (e ? e.name : null)"
    :component-item="aCTemplate"
    :input-attrs="{ placeholder: 'Client', class: 'form-control' }"
    :wait="debounce"
    @update-items="setSearchOptions"
  ></v-autocomplete>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AutoCompleteItemTemplate from '@/components/AutoComplete/AutoCompleteItemTemplate.vue'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'BaseFormClient',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    primaryKey: {
      type: String,
      default: () => 'id',
    },
    defaultId: {
      type: [String, Number],
      default: null,
    },
    defaultLabel: {
      type: [String, Number],
      default: () => 'Client',
    },
    active: {
      type: Boolean,
      default: null,
    },
    debounce: {
      type: Number,
      default: 250,
    },
    autoSelectOneItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      aCTemplate: AutoCompleteItemTemplate,
      selected: null,
      minLen: 1,
      searchOptions: [],

      optionsValue: null,
      defaultValue: {
        code: this.defaultId,
        label: this.defaultLabel,
      },
      ready: false,
    }
  },
  computed: {
    ...mapGetters(['searchResults', 'franchiseClients']),
    // clientList() {
    //   let clientList = [...this.franchiseClients]
    //   clientList = clientList.map((c) => ({
    //     code: c.client.id,
    //     label: `${c.client.fname} ${c.client.lname} - ${c.client.zipCode}`,
    //   }))
    //   clientList.unshift(this.defaultValue)
    //   return clientList
    // },
  },
  methods: {
    ...mapActions(['systemSearch']),
    focus() {
      this.$refs.autocomplete.focus()
    },
    /**
     * search methods
     */
    async setSearchOptions(text) {
      this.selected = null
      this.searchOptions = []
      if (!text) {
        return
      }

      const systemSearch = await this.systemSearch({
        search: 'clients',
        query: text,
      })

      let results = [...this.searchResults]
      for (let k in results) {
        if (results[k].franchiseCode) {
          let option = {
            id: results[k].client.id,
            name: `${results[k].client.fname} ${results[k].client.lname} - ${results[k].client.street1} ${results[k].client.zipCode}`,
            description: null,
          }
          this.searchOptions.push(option)
        }
      }

      return systemSearch
    },
    setLabel(item) {
      if (!item) {
        return null
      }
      return item.name
    },
    async setSearchOptionsFromValue() {
      if (this.value && (!this.selected || this.value != this.selected.id)) {
        console.log('setSearchOptionsFromValue', this.value)
        await this.setSearchOptions(this.value)
        this.$refs.autocomplete.onSelectItem(this.searchOptions[0])
      } else if (!this.value) {
        this.selected = null
      }
    },
  },
  mounted() {
    this.setSearchOptionsFromValue()
  },
  watch: {
    value() {
      this.setSearchOptionsFromValue()
    },
    selected: {
      handler(val) {
        this.$emit('input', val ? val.id : null)
        this.$emit('selected-id', val ? val.id : null)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
#client-query.v-autocomplete {
  position: relative !important;
}

#client-query .v-autocomplete-list {
  position: absolute !important;
  top: 36px;
  left: 0;
  width: 100%;
  z-index: 999;
}

#client-query .v-autocomplete-list-item {
  background-color: #ffff;
}
</style>
