/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import {
  SET_USER_FRANCHISE_LIST,
  SET_CURRENT_FRANCHISE
} from '../mutation-types'

// initial state
const state = {
  userFranchiseList: [],
  currentFranchise: {}
}

// getters
const getters = {
  userFranchiseList: state => state.userFranchiseList,
  currentFranchise: state => state.currentFranchise,
  currentFranchiseCode: state => {
    return state.currentFranchise.franchiseCode || null
  }
}

// actions
const actions = {

  async getDirectorFranchiseList ({ dispatch, commit, state }, userId) {
    try {
      return await HTTP.get('franchise_directors', {
        params: {
          'director.id': userId
        }
      }).then((resp) => {
        commit(SET_USER_FRANCHISE_LIST, resp.data['hydra:member'])
        if (resp.data['hydra:member'][0]) {
          // set primary as default
          dispatch('setCurrentFranchise', state.userFranchiseList.find(e => e.primaryFranchise).franchiseCode)
        }
        return resp.data['hydra:member']
      })
    } catch (e) {
      console.error(e)
    }
  },

  async getEmpFranchiseList ({ dispatch, commit, state }, userId) {
    try {
      const franchise_employees = await HTTP.get('franchise_employees', {
        params: {
          'employee.id': userId
        }
      })
      commit(SET_USER_FRANCHISE_LIST, franchise_employees.data['hydra:member'])
      if (franchise_employees.data['hydra:member'].length > 0) {
        // set first as default
        dispatch('setCurrentFranchise', franchise_employees.data['hydra:member'][0].franchiseCode)
      }
      return franchise_employees
    } catch (e) {
      console.error(e)
    }
  },

  resetUserFranchiseList ({ commit }) {
    commit(SET_USER_FRANCHISE_LIST, [])
  },

  setCurrentFranchise ({ commit }, franchiseCode) {
    const franchise = state.userFranchiseList.find(e => e.franchiseCode === franchiseCode).franchise
    commit(SET_CURRENT_FRANCHISE, franchise)
  },

  resetCurrentFranchise ({ commit }) {
    commit(SET_CURRENT_FRANCHISE, {})
  }
}

// mutations
const mutations = {
  [SET_CURRENT_FRANCHISE] (state, payload) {
    state.currentFranchise = payload
    state.currentFranchiseCode = payload.franchiseCode
  },
  [SET_USER_FRANCHISE_LIST] (state, payload) {
    state.userFranchiseList = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
