/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import {
  SET_USER_STATUS_OPTIONS,
  SET_USER,
  SET_USERS,
  ADD_USER,
  SET_USER_PROFILE,
  SET_USER_PROFILE_ID,
  SET_SSC_REP,
  SET_SSC_REPS,
  ADD_SSC_REP
} from '../mutation-types'

// initial state
const state = {
  userStatusOptions: [],
  users: [],
  user: {},
  userProfile: {},
  userProfileId: null,
  sscReps: [],
  sscRep: {}
}

// getters
const getters = {
  userStatusOptions: state => state.userStatusOptions,
  users: state => state.users,
  user: state => state.user,
  userProfile: state => state.userProfile,
  userProfileId: state => state.userProfileId,
  sscReps: state => state.sscReps,
  sscRep: state => state.sscRep
}

// actions
const actions = {
  getUserStatusOptions ({ commit }) {
    // @TODO create endpoint and connect
    commit(SET_USER_STATUS_OPTIONS, []) 
  },

  getUsers ({ commit }, params) {
    return HTTP.get(constants.API_URI_USERS, { params }).then(resp => {
      commit(SET_USERS, resp.data['hydra:member'])
    })
  },
  getUser ({ commit }, id) {
    return HTTP.get(constants.API_URI_USERS + '/' + id).then(resp => {
      commit(SET_USER, resp.data)
    })
  },
  getUserProfile ({ commit }, id) {
    return HTTP.get(constants.API_URI_USERS + '/' + id).then(resp => {
      commit(SET_USER_PROFILE, resp.data)
    })
  },
  getSscReps ({ commit }, params) {
    params = { ...{
      roles: 'SSC_REP',
      active: true
    }, ...params }
    return HTTP.get(constants.API_URI_USERS, { params }).then(resp => {
      commit(SET_SSC_REPS, resp.data['hydra:member'])
    })
  },
  getSscRep ({ commit }, id) {
    return HTTP.get(constants.API_URI_USERS + '/' + id).then(resp => {
      commit(SET_SSC_REP, resp.data)
    })
  },
  postUser (_, data) {
    return HTTP.post(constants.API_URI_USERS, data)
  },
  putUser ({ commit }, data) {
    return HTTP.put(constants.API_URI_USERS + `/${data.id}`, data)
    .then((resp) => {
      commit(SET_USER, resp.data)
    })
  }
}

// mutations
const mutations = {
  [SET_USER_STATUS_OPTIONS] (state, data) {
    state.userStatusOptions = data
  },
  [SET_USERS] (state, data) {
    state.users = data
  },
  [SET_USER] (state, data) {
    state.user = data
  },
  [SET_USER_PROFILE] (state, data) {
    state.userProfile = data
  },
  [SET_USER_PROFILE_ID] (state, id) {
    state.userProfileId = id
    if (!id) { state.userProfile = {} }
  },
  [ADD_USER] (state, data) {
    state.users.push(data)
  },
  [SET_SSC_REPS] (state, data) {
    state.sscReps = data
  },
  [SET_SSC_REP] (state, data) {
    state.sscRep = data
  },
  [ADD_SSC_REP] (state, data) {
    state.sscReps.push(data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
