<template>
  <div>
    <b-spinner type="grow" small variant="info" label="Loading..."></b-spinner
    >&nbsp;&nbsp;&nbsp;Loading...
  </div>
</template>

<script>
export default {
  name: 'BaseLoading',
}
</script>
