// trying this from https://blog.logrocket.com/error-handling-debugging-and-tracing-in-vue-js/
// import Swal from "sweetalert2"
// import "sweetalert2/dist/sweetalert2.min.css"
import store from '@/store'

export default class ErrorService {
    constructor() {
      console.log('ErrorService Active')
      // this.initHandler()
    }
  
    static onError(error) {
      const response = error.response
      if (response && response.status >= 400 && response.status < 405) {
        ErrorService.logInfo(response.data['hydra:description'])
        return false
      }
      if (response) {
        ErrorService.logError(response.data['hydra:description'])
        store.commit('ACTION_ERROR', response.data['hydra:description'])
        return false
      }
      ErrorService.logError(error)
      store.commit('ACTION_ERROR', error)
    }
  
    static onWarn(error) {
      this.logError(error)
    }
  
    static onInfo(error) {
      this.logInfo(error)
    }
  
    static onDebug(error) {
      const response = error.response
      if (response && response.status >= 400 && response.status < 405) {
        this.logInfo(error)
        return false
      }
      this.logError(error)
    }
  
    static initHandler() {
      const scope = this
      window.onerror = (message, url, lineNo, columnNo, error) => {
        console.log('test', error)
        if (error) {
          scope.onError(error)
          console.error(message, url, lineNo, columnNo, error)
        }
      }
    }
  
    static displayErrorAlert(message) {
      // @TODO
      // could use Swal.fire({
      //   title: "Error!",
      //   text: message,
      //   icon: "error",
      // })
      console.error('httpStatusError', message)
    }
  
    static logError(error) {
      // @TODO
      console.error('error', error)
    }
  
    static logInfo(error) {
      // @TODO
      console.info('httpStatusError', error)
    }
  }
