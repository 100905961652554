/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import {
  SET_CURRENT_ERROR,
  SET_CURRENT_USER_TOKEN,
  SET_CURRENT_USER,
  SET_CURRENT_USER_PROFILE,
  LOGOUT
} from '../mutation-types'
import router from '@/router'

// initial state
const state = {
  userError: null,
  userToken: null,
  currentUser: null
}

// getters
const getters = {
  isAdmin: state => (state.currentUser.roles.includes('ROLE_ADMIN') ||
    state.currentUser.roles.includes('ROLE_SUPER_ADMIN')),
  isDirector: state => (state.currentUser.roles.includes('ROLE_DIRECTOR')),
  isStaff: state => (state.currentUser.roles.includes('ROLE_TUTOR') || state.currentUser.roles.includes('ROLE_EC')),
  isAuthenticated: state => !!state.userToken,
  userError: state => state.userError,
  userRole: state => state.currentUser ? state.currentUser.roles[0] : null,
  currentUser: state => state.currentUser
}

// actions
const actions = {

  async autoLogin ({ dispatch, commit, app }) {
    const token = localStorage.getItem(constants.LOCAL_STORAGE_TOKEN_NAME)
    const username = localStorage.getItem(constants.LOCAL_STORAGE_USERNAME)
    if (!token || !username) {
      return false
    }
    commit(SET_CURRENT_USER_TOKEN, token)
    await dispatch('getCurrentUser')
    return true
  },

  async login ({ dispatch, commit, state }, creds) {
    commit(LOGOUT)
    try {
      await dispatch('authenticateCurrentUser', creds)
      const currentUser = await dispatch('getCurrentUser')
      if (state.isAuthenticated) {
        commit(SET_CURRENT_ERROR, null)
      }
      return currentUser
    } catch (e) {
      console.error(e)
    }
  },

  resetPassword (_, data) {
    return HTTP.post('reset_password', data).then(resp => {
      return resp.data
    })
  },

  async authenticateCurrentUser ({ commit }, creds) {
    try {
      const token = await HTTP.post('authentication_token', {
        username: creds.username,
        password: creds.password
      })
      commit(SET_CURRENT_USER_TOKEN, token.data.token)
      return token
    } catch (e) {
      commit(SET_CURRENT_ERROR, e.response.data.message)
    }
  },

  async getCurrentUser ({ dispatch, commit, getters }) {
    if (!getters.isAuthenticated) {
      return
    }
    const params = { active: true }
    return await HTTP.get(constants.API_URI_ME, { params }).then(async resp => {
      if (!resp.data.id) {
        commit(LOGOUT)
        commit(SET_CURRENT_ERROR, 'Could not locate active user!')
      } else {
        commit(SET_CURRENT_USER, resp.data)
        if (resp.data.roles.includes('ROLE_DIRECTOR')) {
          await dispatch('getDirectorFranchiseList', resp.data.id)
        } else if (resp.data.roles.includes('ROLE_TUTOR')) {
          await dispatch('getEmpFranchiseList', resp.data.id)
        }
      }
    }, e => {
      console.error(e)
    })
  },

  async impersonate ({ commit, dispatch }, username) {
    const headers = {}
    headers['X-Switch-User'] = username
    return await HTTP.get(constants.API_URI_ME, { headers }).then(async resp => {
      if (!resp.data.id) {
        commit(SET_CURRENT_ERROR, 'Could not locate active user!')
      } else {
        resp.data.isImpersonating = true
        commit(SET_CURRENT_USER, resp.data)
        if (resp.data.roles.includes('ROLE_DIRECTOR')) {
          await dispatch('getDirectorFranchiseList', resp.data.id)
        } else if (resp.data.roles.includes('ROLE_TUTOR')) {
          await dispatch('getEmpFranchiseList', resp.data.id)
        }
        dispatch('addModal', { title: 'You logged in as ' + resp.data.username, content: null, size: 'sm', bodyClass: 'text-center' })
        router.push({ name: 'Dashboard' })
      }
    }, e => {
      console.error(e)
    })
  },

  impersonateExit ({ commit, dispatch }) {
    const headers = {}
    return HTTP.get(constants.API_URI_ME, { params: {
      '_switch_user': '_exit'
    } }).then(resp => {
      commit(SET_CURRENT_USER, resp.data)
      dispatch('resetUserFranchiseList')
      dispatch('resetCurrentFranchise')
      dispatch('addModal', { title: 'Welcome Back!', content: null, size: 'sm', bodyClass: 'text-center' })
      router.push({ name: 'Dashboard' })
    }, e => {
      console.error(e)
    })
  },

  logout ({ dispatch, commit }) {
    commit(LOGOUT)
    dispatch('resetUserFranchiseList')
    dispatch('resetCurrentFranchise')
  },

  putCurrentUser ({ commit, state }, data) {
    return HTTP.put(constants.API_URI_USERS + `/${state.currentUser.id}`, data)
    .then((resp) => {
      commit(SET_CURRENT_USER, resp.data)
    })
  },

  putCurrentUserProfile ({ commit, state }, data) {
    return HTTP.put(constants.API_URI_USER_PROFILES + `/${state.currentUser.profile.id}`, data)
    .then((resp) => {
      commit(SET_CURRENT_USER_PROFILE, resp.data)
    })
  }
}

// mutations
const mutations = {
  [SET_CURRENT_ERROR] (state, error) {
    state.userError = error
  },
  [SET_CURRENT_USER_TOKEN] (state, token) {
    state.userToken = token
    localStorage.setItem(constants.LOCAL_STORAGE_TOKEN_NAME, state.userToken)
  },
  [SET_CURRENT_USER] (state, data) {
    state.currentUser = data
    localStorage.setItem(constants.LOCAL_STORAGE_USERNAME, state.currentUser.username)
    /*
    state.user.franchiseList = data.franchise_list
    // franchise data
    if (data.franchise_list !== '') {
      let franchiseList = data.franchise_list.split(',')
      state.user.primaryFranchise = franchiseList[0]
    }
    state.user.subjectList = data.subject_list
    */
  },
  [SET_CURRENT_USER_PROFILE](state, data){
    state.currentUser.profile = data
  },
  [LOGOUT] (state) {
    state.userToken = null
    state.currentUser = null
    state.userError = null
    localStorage.removeItem(constants.LOCAL_STORAGE_TOKEN_NAME)
    localStorage.removeItem(constants.LOCAL_STORAGE_USERNAME)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
