const LS_PREFIX = 'cz'
export const constants = Object.freeze({
  GLOBAL_INPUT_DEBOUNCE: 500,
  CORPORATE_FRANCHISE_CODE: '0',
	CLIENT_STATUS_OPTIONS: [
		'Prospect',
		'Pending',
		'Active',
		'Inactive'
	],
  CLIENT_CONTACT_RELATIONSHIPS: [
    {value: 'Mother', text: 'Mother'},
    {value: 'Father', text: 'Father'},
    {value: 'Grand Mother', text: 'Grand Mother'},
    {value: 'Grand Father', text: 'Grand Father'},
    {value: 'Other Guardian', text: 'Other Guardian'},
    {value: 'Self', text: 'Self'},
    {value: 'Other', text: 'Other'}
  ],
  CLIENT_KEY_SEPARATOR: '_',
  CLIENT_KEY_REMOVE_REGEX: /[^a-z]/i,
  GROUP_ID_HOUSEHOLD: 1,
  LOCAL_STORAGE_PREFIX_TOUR: 'tour-',
  LOCAL_STORAGE_TOKEN_NAME: LS_PREFIX + '-fms-token',
  LOCAL_STORAGE_USERNAME: LS_PREFIX + '-fms-user',

  CLIENT_APPOINTMENT_TYPE_ENROLLMENT: 'enrollment',
  CLIENT_APPOINTMENT_TYPE_SESSION: 'session',

  DEFAULT_PHONE_OBJ: {
    id: null,
    primary: true,
    number: null
  },

  SUPPORT_LEVELS: [
    {id: 0, label: 'Pending'},
    {id: 1, label: 'Independent'},
    {id: 2, label: 'No SSC'},
    {id: 3, label: 'Club Z! SSC'},
    {id: 4, label: 'Alternate SSC'}
  ],

  FORM_ID_CONTACT: 6,
  FORM_ID_CONTACT_CLIENT_FIELD_REL: 4,
  FORM_ID_CONTACT_CLIENT_FIELD_ID: 5,
  FORM_ID_CONTACT_GROUP_FIELD_ID: 25,
  FORM_ID_CONTACT_GROUP_FIELD_PRIMARY: 23,
  FORM_ID_SCHOOL: 7,
  FORM_ID_SCHOOL_FC_FIELD_ID: 21,

  IMAGE_DIR_USER_PROFILE: 'user_profile',

  // USER ROLES /////////////////////////////////
  USER_ROLE_ADMIN: 'ROLE_ADMIN',
  USER_ROLE_SSC_ADMIN: 'ROLE_SSC_ADMIN',
  USER_ROLE_SSC_REP: 'ROLE_SSC_REP',
  USER_ROLE_EC: 'ROLE_EC',
  USER_ROLE_TUTOR: 'ROLE_TUTOR',

	// API IRIs /////////////////////////////////
  API_IRI_GROUP_TYPES: '/api/group_types/',
  API_IRI_GROUP_TYPES_HOUSEHOLD: '/api/group_types/1',
  API_IRI_CLIENTS: '/api/clients/',
  
	// API URIs /////////////////////////////////
	API_URL: 'https://api.clubz.com/api/',
  API_URI_USERS: 'users',
  API_URI_ME: 'me',
  API_URI_USER_PROFILES: 'user_profiles',
  API_URI_CLIENT_APPOINTMENTS: 'client_appointments',
  API_URI_CLIENT_APPOINTMENT_CONFIRMATINS: 'client_appointment_confirmations',
  // franchise
  API_URI_FRANCHISES: 'franchises',
  API_URI_FRANCHISE_DIRECTORS: 'franchise_directors',
  API_URI_FRANCHISE_SETTINGS: 'franchise_settings',
  API_URI_FRANCHISE_MARKETING: 'franchise_promotions',
  API_URI_FRANCHISE_SERVICE_GROUPS: 'franchise_service_groups',
  API_URI_FRANCHISE_SERVICES: 'franchise_services',
  API_URI_FRANCHISE_EMPLOYEES: 'franchise_employees',
  API_URI_FRANCHISE_SUBSCRIPTIONS: 'franchise_subscriptions',
  API_URI_SSC_NOTES: 'ssc_notes',
  API_URI_ZIP_CODES: 'zip_codes',
  // client
  API_URI_CLIENTS: 'clients',
  API_URI_CLIENT_PROFILES: 'client_profiles',
  API_URI_CLIENT_PARENTS: 'client_parents',
  API_URI_CLIENT_STATUS_OPTIONS: 'client_status_options',
  API_URI_EMP_CLIENTS: 'client_emps',
  API_URI_FRANCHISE_CLIENTS: 'franchise_clients',
  // lead
  API_URI_LEADS: 'leads',
  API_URI_LEAD_NOTES: 'lead_notes',
  API_URI_LEAD_STATUSES: 'lead_statuses',
  API_URI_LEAD_CAMPAIGNS: 'lead_campaigns',

  API_URI_GROUPS: 'groups',
  API_URI_GROUP_USERS: 'group_users',
  API_URI_GROUP_CLIENTS: 'group_clients',

  API_URI_FORM_SUBMISSIONS: 'form_submissions',
  API_URI_FORM_SUBMISSION_VALUES: 'form_submission_values',

  API_URI_CALENDAR_ENTRIES: 'calendar_entry',
  API_URI_PHONES: 'phones',

  API_URI_US_ZIP_CODES: 'us_zip_codes'
})