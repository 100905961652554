<template>
  <b-form-select
    :value="value"
    :options="marketingList"
    id="franchise"
    ref="selectMarketing"
    :required="required"
    @change="$emit('input', $event)"
    >
    <template v-if="!noLabel" #first>
      <b-form-select-option
        :value="null"
        disabled
      >-- Source --</b-form-select-option>
    </template>
  </b-form-select>
</template>
<script>
// import states from '@/shared/country_states'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BaseFormSelectClientSource',
  props: {
    value: {
      type: String,
      default: () => null
    },
    required: {
      type: Boolean,
      default: false
    },
    franchiseCode: {
      type: String,
      default: null
    },
    noLabel: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      franchiseMarketing: [],
      marketingNotListed: {
        value: '/api/franchise_promotions/1194',
        text: 'NOT LISTED'
      }
    }
  },
  computed: {
    ...mapGetters([
      'franchise',
      'marketingGlobal'
    ]),
    marketingList () {
      const ret = []
      const marketing = [ ...this.marketingGlobal, ...this.franchiseMarketing ]
      if (!marketing.length) {
        return ret
      }
      let c = 0
      let categoryIndex
      let marketingIndex
      for (const i in marketing) {
        categoryIndex = ret.findIndex(
          ({ categoryId }) => categoryId === marketing[i].category.id
        )
        if (categoryIndex === -1) {
          ret[c] = {
            categoryId: marketing[i].category.id,
            label: marketing[i].category.name,
            options: [],
          }
          categoryIndex = c
          c++
        }
        marketingIndex = ret[categoryIndex].options.findIndex(
          ({ value }) => value === marketing[i].id
        )
        if (marketingIndex === -1) {
          let text = marketing[i].resource
            ? marketing[i].resource.source
            : null
          if (typeof marketing[i].resourceExtra !== "undefined" && marketing[i].resourceExtra) {
            text = text + ` (${marketing[i].resourceExtra})`
          }
          ret[categoryIndex].options.push({
            value: marketing[i]["@id"],
            text: `${marketing[i].category.name}: ${text}`,
          })
        }
      }
      return ret
    }
  },
  methods: {
    ...mapActions([
      'getGlobalMarketing',
      'getFranchiseMarketing',
      'resetFranchiseMarketing'
    ]),
    async doGetFranchiseMarketing () {
      if (this.franchiseCode) {
        await this.getFranchiseMarketing(this.franchiseCode)
        this.franchiseMarketing = this.franchise.marketing
      } else {
        this.resetFranchiseMarketing()
        this.franchiseMarketing = []
      }
    }
  },
  mounted () {
    this.getGlobalMarketing()
    this.doGetFranchiseMarketing()
  },
  watch: {
    franchiseCode () {
      this.doGetFranchiseMarketing()
    }
  }
}
</script>
