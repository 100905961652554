import Vue from 'vue'
import Vuex from 'vuex'
import { Ability } from '@casl/ability'
// plugins
// import VuexPersistence from 'vuex-persist'
import abilityPlugin from './ability'
// modules
import appointment from './modules/appointment'
import client from './modules/client'
import lead from './modules/lead'
import franchise from './modules/franchise'
import group from './modules/group'
import currentUser from './modules/currentUser'
import currentFranchise from './modules/currentFranchise'
import global from './modules/global'
import pcmap from './modules/pcmap'
import phone from './modules/phone'
import search from './modules/search'
import zipCode from './modules/zipCode'
import user from './modules/user'
import form from './modules/form'
import media from './modules/media'
import modal from './modules/modal'

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage
// })

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const getters = {
  ability() {
    return new Ability()
  }
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  plugins: [
    abilityPlugin
    // plugins: [vuexLocal.plugin]
  ],
  state,
  getters,
  mutations,
  modules: {
    appointment,
    user,
    search,
    pcmap,
    phone,
    global,
    group,
    franchise,
    currentUser,
    currentFranchise,
    client,
    lead,
    zipCode,
    form,
    media,
    modal
  },
  strict: true
})
