<template>
  <v-select
    v-if="ready"
    id="director"
    ref="selectDirector"
    class="form-control"
    :value="optionsValue"
    :options="directorList"
    :required="required"
    @input="onInput"
  >
  </v-select>
  <div v-else>LOADING...</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'BaseFormSelectDirector',
  components: { vSelect },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    primaryKey: {
      type: String,
      default: () => ('@id')
    },
    defaultCode: {
      type: [ String, Number ],
      default: null
    },
    defaultLabel: {
      type: [ String, Number ],
      default: () => ('Director')
    }
  },
  data () {
    return {
      optionsValue: null,
      defaultValue: {
        code: this.defaultCode,
        label: this.defaultLabel
      },
      ready: false
    }
  },
  computed: {
    ...mapGetters([]),
    directorList () {
      let directorList = []
      directorList = directorList.map(f => ({
        code: `${f[this.primaryKey]}`,
        label: ''
      }))
      directorList.unshift(this.defaultValue)
      return directorList
    }
  },
  methods: {
    ...mapActions([]),
    onInput (selected) {
      this.optionsValue = selected ? selected : this.defaultValue
      this.$emit('input', this.optionsValue.code)
    },
    setSelected (code) {
      this.optionsValue = this.directorList.find(e => e.code === code) || this.defaultValue
    },
    focus () {
      this.$refs.selectFranchise.focus()
    }
  },
  async mounted () {
    this.ready = true
    this.setSelected(this.value)
  }
}
</script>

<style lang="scss">
#franchise {
  .vs__dropdown-toggle {
    border: none;
  }
  .vs__selected {
    margin: 0;
  }
  .vs__actions {
    padding: 0;
  }
}
</style>