import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import i18n from '@/plugins/i18n'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const Calendar = () => import('@/views/calendar/Calendar')
// User
const UserProfileImageEdit = () => import('@/views/user/UserProfileImageEdit')
const UserList = () => import('@/views/user/UserList')
// Client
const ClientList = () => import('@/views/clients/ClientList')
const ClientAppointments = () => import('@/views/clients/ClientAppointments')
const ClientAppointmentView = () => ({})
const ClientForm = () => import('@/views/clients/-ClientForm')
// billing
const BillingDetails = () => import('@/views/billing/BillingDetails')
const BillingList = () => import('@/views/billing/BillingList')
// Lead
const LeadList = () => import('@/views/leads/LeadList')
const LeadDetails = () => import('@/views/leads/LeadDetails')
const LeadForm = () => import('@/components/lead/LeadForm')
// SSC
const SscBooking = () => import('@/views/ssc/Ssc')
// Franchise
const FranchiseDirectorDetails = () => import('@/views/franchise/FranchiseDirectorDetails')
const FranchiseList = () => import('@/views/franchise/FranchiseList')
const FranchiseDetails = () => import('@/views/franchise/FranchiseDetails')
// Reporting
const ReportingOverview = () => import('@/views/reporting/ReportingOverview')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const PasswordReset = () => import('@/views/PasswordReset')

const homePage = () => ({
  'ROLE_TUTOR': '/dates',
  'ROLE_SSC_REP': '/ssc',
  'ROLE_SSC_ADMIN': '/ssc',
  'default': '/'
})

const ifAuthenticated = (_, __, next) => {
  if (store.getters.isAuthenticated && store.getters.currentUser) {
    next()
  }
  else next('/login')
}

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to, _, next) => {
  if (
    to.name !== 'Login' &&
    to.name !== 'PasswordReset' &&
    !store.getters.isAuthenticated
  ) {
    store.dispatch('autoLogin').then(resp => {
      if (!resp) next({ name: 'Login' })
      else next()
    })
  }
  else next()
})

export default router

function configRoutes () {
  return [
    {
      path: '/',
      redirect: homePage[store.getters.userRole || 'default'],
      name: 'Home',
      meta: { label: i18n.t('nav.home') },
      component: TheContainer,
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          meta: { label: i18n.t('nav.dashboard') },
          component: Dashboard
        },
        {
          path: 'events',
          redirect: '/events/calendar',
          name: 'Dates',
          meta: { label: i18n.t('nav.dates') },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'calendar',
              name: 'Calendar',
              meta: { label: i18n.t('nav.calendar') },
              component: Calendar
            },
            {
              path: 'appointments/:type?/:id?',
              name: 'ClientAppointments',
              meta: { label: i18n.t('nav.clients.appointment') },
              component: ClientAppointments
            },
            {
              path: 'appointments/view/:id',
              name: 'ClientAppointmentView',
              meta: { label: i18n.t('nav.clients.appointment') },
              component: ClientAppointmentView
            }
          ]
        },
        {
          path: 'ssc',
          redirect: '/ssc/booking',
          name: 'Ssc',
          meta: { label: i18n.t('nav.ssc.ssc') },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'booking',
              name: 'SscBooking',
              meta: { label: i18n.t('nav.ssc.booking') },
              component: SscBooking
            }
          ]
        },
        {
          path: 'clients',
          redirect: '/clients/list',
          name: 'Clients',
          meta: { label: i18n.t('nav.clients.clients') },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'ClientList',
              meta: {
                label: i18n.t('nav.clients.list'),
                title: `${i18n.t('nav.clients.client')} ${i18n.t('nav.common.list')}`
              },
              component: ClientList
            },
            {
              path: 'form',
              name: 'ClientForm',
              meta: {
                label: i18n.t('nav.common.form'),
                title: `${i18n.t('nav.clients.client')} ${i18n.t('nav.common.form')}`
              },
              component: ClientForm
            },
            {
              path: 'billing',
              name: 'BillingList',
              meta: {
                label: i18n.t('nav.common.billing'),
                title: `${i18n.t('nav.clients.client')} ${i18n.t('nav.common.billing')}`
              },
              component: BillingList
            },
            {
              path: 'billing/:id',
              name: 'BillingDetails',
              meta: {
                label: i18n.t('nav.common.billing'),
                title: `${i18n.t('nav.clients.client')} ${i18n.t('nav.common.billing')} ${i18n.t('nav.common.details')}`
              },
              component: BillingDetails
            }
          ]
        },
        {
          path: 'leads',
          redirect: '/leads/list',
          name: 'Leads',
          meta: { label: i18n.t('nav.leads.leads') },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'LeadList',
              meta: { label: i18n.t('nav.leads.list') },
              component: LeadList
            },
            {
              path: 'details/:id',
              name: 'LeadDetails',
              meta: { label: i18n.t('nav.leads.details') },
              component: LeadDetails
            },
            {
              path: 'form/:id?',
              name: 'LeadForm',
              meta: { label: i18n.t('nav.leads.form') },
              component: LeadForm
            }
          ]
        },
        {
          path: 'franchise',
          redirect: '/franchise/directory',
          name: 'Franchise',
          meta: { label: i18n.t('nav.franchise.franchise') },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'director/:user',
              name: 'FranchiseDirectorDetails',
              meta: { label: i18n.t('nav.franchise.directorDetails') },
              component: FranchiseDirectorDetails
            },
            {
              path: 'directory',
              name: 'FranchiseDirectory',
              meta: { label: i18n.t('nav.franchise.list') },
              component: FranchiseList
            },
            {
              path: ':franchiseCode',
              name: 'FranchiseDetails',
              meta: { label: i18n.t('nav.franchise.details') },
              component: FranchiseDetails
            }
          ]
        },
        {
          path: 'reporting',
          redirect: '/reporting/overview',
          name: 'Reporting',
          meta: { label: i18n.t('nav.reporting.reporting') },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'overview',
              name: 'ReportingOverview',
              meta: { label: i18n.t('nav.reporting.reporting') },
              component: ReportingOverview
            }
          ]
        },
        {
          path: 'user',
          // redirect: '/user/edit/:user',
          redirect: 'edit/profile-image',
          name: 'User',
          meta: { label: 'User' },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'edit/profile-image/:user?',
              name: 'UserProfileImageEdit',
              meta: { label: 'Profile Image Edit' },
              component: UserProfileImageEdit
            }
          ]
        },
        {
          path: 'system',
          redirect: '/system/users',
          name: 'System',
          meta: { label: 'System' },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'users',
              name: 'SystemUsers',
              meta: { label: 'Users' },
              component: UserList
            }
          ]
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/password-reset',
      name: 'PasswordReset',
      component: PasswordReset
    },
    {
      path: '/error',
      redirect: '/error/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        }
      ]
    }
  ]
}

