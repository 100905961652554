/* eslint-disable */
import { HTTP } from '@/services/http-common'

const state = {
  media: [],
  mediaObject: {}
}

const getters = {
  media: state => state.media,
  mediaObject: state => state.mediaObject
}

const actions = {
  postMedia ({ commit }, data) {
    return HTTP.post('media_objects', data)
    .then(async resp => {
      return resp.data
    })
  },
  getMedia ({ commit }, params) {
    return HTTP.get('media_objects', { params }).then(resp => {
      return resp.data['hydra:member']
    })
  }
}

const mutations = {
}

export default {

  state,
  getters,
  actions,
  mutations
}
