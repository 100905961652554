import { constants } from '@/shared/constants'
// import { access } from '@/shared/access-roles'

export default {
  data: () => ({
    viewFranchiseCode: null
  }),
  computed: {
    storageItemPrefix () {
      return this.$route.name + '_'
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    isDirector () {
      return this.$store.getters.currentUser.roles.includes('ROLE_DIRECTOR')
    },
    isStaff () {
      return this.$store.getters.currentUser.roles.includes('ROLE_TUTOR') || this.$store.getters.currentUser.roles.includes('ROLE_EC')
    },
    currentUser () {
      return this.$store.getters.currentUser
    },
    currentFranchise () {
      return this.$store.getters.currentFranchise
    },
    currentFranchiseCode () {
      return this.$store.getters.currentFranchiseCode
    },
    currentUserProfileName () {
      return `${this.$store.getters.currentUser.profile.fname} ${this.$store.getters.currentUser.profile.lname}`
    },
    gInputDebounce () {
      return constants.GLOBAL_INPUT_DEBOUNCE
    },
    canImpersonate () {
      for (let i in this.currentUser.roles) {
        if (['ROLE_ADMIN','ROLE_SUPER_ADMIN'].includes(this.currentUser.roles[i])) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    /**
     * sets a localStorage item
     * @param {string} item the name of the item
     * @param {mixed} val the value of the item
     */
    setStorageItem (item, val) {
      const itemName = this.storageItemPrefix + item
      const itemVal = !['object','array'].includes(typeof val) ? val : JSON.stringify(val)
      // console.log('setStorageItem', itemName, itemVal)
      localStorage.setItem(itemName, itemVal)
    },
    /**
     * gets a localStorage item
     * @param {string} item the name of the item
     * @param {mixed} defaultVal the default value of the item if none exists
     */
    getStorageItem (item, defaultVal) {
      const itemName = this.storageItemPrefix + item
      let val = localStorage.getItem(itemName)
      // console.log('getStorageItem', itemName, val)
      val = JSON.parse(val) || val || defaultVal
      // console.log('getStorageItem', itemName, val)
      return val
    },
    /**
     * scroll to x point, 'top' or $ref val
     * @param target int, 'top' or $ref attr val
     */
    scrollTo (target) {
      if (window) {
        let top
        if (!isNaN(target)) {
          top = parseInt(target)
        } else if (!target || target === 'top') {
          top = 0
        } else {
          const e = this.$refs[target]
          top = e.offsetTop || 0
        }
        window.scrollTo(0, top)
      }
    },
    userImage (user) {
      return user.profile && user.profile.image ? constants.API_URL + '..' + user.profile.image.contentUrl : null
    },
    canAccess (item, relativeId, override) { // @TODO remove override
      console.log('canAccess', item, relativeId)
      if (typeof override !== 'undefined' && override !== null) return override
      return this.$can('read', item)
    },
    canEdit (item, relativeId) {
      console.log('canEdit', item, relativeId)
      // @TODO
      return true
    },
    toastError (mgs) {
      const h = this.$createElement

      // title
      const vNodesTitle = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [ h('strong', { class: 'mr-2' }, 'Error!') ]
      )

      // message
      const vNodesMsg = h(
          'p',
          { class: ['mb-0'] },
          [
            h('b-spinner', { props: { type: 'grow', small: true }, class: ['mr-2'] }),
            h('strong', mgs)
          ]
      )

      this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: 'danger'
      })
      // console.error(mgs)
      // throw new Error(mgs)
    },
    toastSuccess (mgs) {
      const h = this.$createElement

      // title
      const vNodesTitle = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [ 'Confirmation' ]
      )

      // message
      const vNodesMsg = h(
          'p',
          { class: ['mb-0'] },
          [ mgs ]
      )

      this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: 'info'
      })
    },
    /**
     * grabs setting value from settings object
     */
    async getFranchiseSetting (name, section, setting, def) {
      if (!this.$store.getters.franchise.franchiseCode) {
        Error('getFranchiseSetting() - an active franchise code is required')
      }
      if (!this.$store.getters.franchise.settings) {
        await this.$store.dispatch('getFranchiseSettings', this.franchise.franchiseCode)
      }
      const s = this.$store.getters.franchise.settings.find(e => e.name === name)
      if (s) {
        return s.settings[section][setting]
      }
      return def || null
    },
    ModalFranchiseShow (fc, noReset) {
      // @XXX should be actions, if we even go this route
      this.$store.commit('SET_VIEW_FRANCHISE_CODE', fc) 
      this.$store.commit('SET_VIEW_FRANCHISE_CODE_NORESET', noReset || false)
      this.$bvModal.show('modal-franchise-details')
    }
  },
  filters: {
    'datetime': function (str, format) {
      return this.dayjs(str).format(format)
    },
    nl2br: function (str) {
      if (!str) return ''
      const breakTag = '<br ' + '/>'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    },
    formatPhoneNumber: function (str) {
      if (!str) return null
      var cleaned = ('' + str.replace(/^1/, '')).replace(/\D/g, '')
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    },
    formatMoney: function (float) {
      return '$' + float
    },
    linkUrls (str) {
      const exp = /(\b((https?|ftp|file):\/\/)[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig
      return str.replace(exp, "<a href='$1' target='_blank'>$1</a>")
    },
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    lowerHyphen (str) {
      return str.toLowerCase().replace(/[^0-9a-z-\s]/gi, '').replace(/\s/g, '-')
    }
  },
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // check that click was outside the el and has children
          if (!(el == event.target || el.contains(event.target))) {
            // if it was, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
}
