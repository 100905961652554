/* eslint-disable */
import { HTTP } from '@/services/http-common'
import country_states from '@/shared/country_states'
import {
  SET_LOADING,
  UNSET_LOADING,
  ACTION_PENDING,
  ACTION_COMPLETE,
  ACTION_COMPLETE_ALL,
  ACTION_ERROR,
  ACTION_CLEAR_ERROR,
  SET_STATES_LIST
} from '../mutation-types'

// initial state
const state = {
  appName: process.env.VUE_APP_NAME,
  appVersion: process.env.VUE_APP_VERSION,
  currentLoading: 0,
  isLoading: false,
  pendingList: [],
  pending: false,
  error: [],
  statesList: {}
}

// getters
const getters = {
  appName: state => state.appName,
  appVersion: state => state.appVersion,
  isLoading: state => (state.currentLoading > 0),
  pending: state => state.pending,
  error: state => state.error,
  statesList: state => state.statesList
}

// actions
const actions = {
  actionPending ({ commit }, action) {
    commit(ACTION_PENDING, action)
  },
  actionComplete ({ commit }, action) {
    commit(ACTION_COMPLETE, action)
  },
  actionCompleteAll ({ commit }) {
    commit(ACTION_COMPLETE_ALL)
  },
  actionSuccess ({ commit }, action) {
    commit(ACTION_COMPLETE, action)
  },
  actionError ({ commit }, data) {
    commit(ACTION_ERROR, data.error)
    commit(ACTION_COMPLETE, data.action)
  },
  actionClearErrorAll ({ commit }) {
    commit(ACTION_CLEAR_ERROR)
  },
  actionClearError ({ commit }, key) {
    commit(ACTION_CLEAR_ERROR, key)
  },
  getStatesList ({ commit, state }, country) {
    commit(SET_STATES_LIST, country)
    return state.statesList
  }
}

// mutations
const mutations = {
  [SET_LOADING] (state) {
    // state.isLoading = isLoading
    state.currentLoading++
  },
  [UNSET_LOADING] (state) {
    // state.isLoading = isLoading
    state.currentLoading--
  },
  [ACTION_PENDING] (state, action) {
    if (action && !state.pendingList.includes(action)) {
      state.pendingList.push(action)
    }
    state.pending = true
  },
  [ACTION_COMPLETE] (state, action) {
    if (action) {
      for (let i = 0; i <= state.pendingList.length - 1; i++) {
        if (state.pendingList[i] === action) {
          state.pendingList.splice(i, 1)
        }
      }
    }
    if (state.pendingList.length === 0) {
      state.pending = false
    }
  },
  [ACTION_COMPLETE_ALL] (state) {
    state.pendingList = []
    state.pending = false
  },
  [ACTION_ERROR] (state, message) {
    let k = state.error.length || 0
    state.error[k] = message
  },
  [ACTION_CLEAR_ERROR] (state, key) {
    if (key) {
      state.error.splice(key, 1)
    } else {
      state.error = []
    }
  },
  [SET_STATES_LIST] (state, country) {
    if (country && country_states[country]) {
      let keys = Object.keys(country_states[country])
      for (let k in keys) {
        let item = {
          value: keys[k],
          text: country_states[country][keys[k]]
        }
        state.statesList.push(item)
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
