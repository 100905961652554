/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import {
    SET_GROUP
} from '../mutation-types'

const state = {
  group: {}
}

const getters = {
  group: state => state.group
}

const actions = {
  getGroup ({ commit }, id) {
    return HTTP.get(constants.API_URI_GROUPS + `/${id}`).then(resp => {
      commit(SET_GROUP, resp.data)
      return resp.data
    })
  },
  postGroup ({ commit }, payload) {
    return HTTP.post(constants.API_URI_GROUPS, payload).then(resp => {
      commit(SET_GROUP, resp.data)
      return resp.data
    })
  },
  resetGroup ({ commit }) {
    commit(SET_GROUP, {})
  },
  postGroupUser (_, payload) {
    return HTTP.post(constants.API_URI_GROUP_USERS, payload).then(resp => {
      return resp.data
    })
  },
  postGroupClient (_, payload) {
    return HTTP.post(constants.API_URI_GROUP_CLIENTS, payload).then(resp => {
      return resp.data
    })
  }
}

const mutations = {
  [ SET_GROUP ] (state, payload) {
    state.group = payload
  }
}

export default {

  state,
  getters,
  actions,
  mutations
}
