/* eslint-disable */
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import {
	SET_LEADS,
	SET_LEADS_TOTAL_UNFILTERED,
  UPDATE_LEAD,
	SET_LEAD,
	SET_LEAD_STATUS_OPTIONS,
	SET_DRIP_CAMPAIGN_OPTIONS,
  SET_LEAD_NOTES,
  ADD_LEAD_NOTE
} from '../mutation-types'

const state = {
  leads: [],
  leadsTotalUnfiltered: 0,
  lead: {},
  leadStatusOptions: [],
  dripCampaignOptions: [],
  leadNotes: []
}

const getters = {
  leads: state => state.leads,
  leadsTotalUnfiltered: state => state.leadsTotalUnfiltered,
  lead: state => state.lead,
  leadStatusOptions: state => state.leadStatusOptions,
  dripCampaignOptions: state => state.dripCampaignOptions,
  leadNotes: state => state.leadNotes
}

const actions = {
  getLeadStatuses ({ commit }) {
    return HTTP.get(constants.API_URI_LEAD_STATUSES, {
      params: {
        archived: false
      }
    }).then(resp => {
      commit(SET_LEAD_STATUS_OPTIONS, resp.data['hydra:member'])
    })
  },
  getDripCampaigns ({ commit }, params) {
    return HTTP.get(constants.API_URI_LEAD_CAMPAIGNS, { params }).then(resp => {
      commit(SET_DRIP_CAMPAIGN_OPTIONS, resp.data['hydra:member'])
    })
  },
  
  // get leads
  getLeads ({ commit }, params) {
    return HTTP.get(constants.API_URI_LEADS, { params }).then(resp => {
    	commit(SET_LEADS, resp.data['hydra:member'])
 		  commit(SET_LEADS_TOTAL_UNFILTERED, resp.data['hydra:totalItems'])
    })
  },

  resetLeads ({ commit }) {
    commit(SET_LEADS, [])
    commit(SET_LEADS_TOTAL_UNFILTERED, 0)
  },

  // get single lead
  getLead ({ commit }, leadId) {
    return HTTP.get(constants.API_URI_LEADS + '/' + leadId)
    .then(resp => {
    	commit(SET_LEAD, resp.data)
    })
  },

  // get single lead
  patchLead ({ commit }, lead) {
    return HTTP.put(constants.API_URI_LEADS + '/' + lead.id, lead)
    .then(resp => {
    	commit(UPDATE_LEAD, resp.data)
    })
  },

  postLead ({ commit }, params) {
    return HTTP.post(constants.API_URI_LEADS, params)
    .then(resp => {
    	commit(SET_LEAD, resp.data)
    })
  },

  putLead ({ commit }, data) {
    if (!data.id) Error('"id" proerty is required')
    return HTTP.put(constants.API_URI_LEADS + '/' + data.id, data)
    .then(resp => {
    	commit(SET_LEAD, resp.data)
    })
  },

  resetLead ({ commit }) {
    commit(SET_LEAD, {})
    commit(SET_LEAD_NOTES, [])
  },

  resetLeads ({ commit }) {
    commit(SET_LEADS, {})
  },

  getLeadNotes ({ commit }, params) {
    return HTTP.get(constants.API_URI_LEAD_NOTES, { params }).then(resp => {
    	commit(SET_LEAD_NOTES, resp.data['hydra:member'])
    })
  },

  postLeadNote ({ commit }, data) {
    return HTTP.post(constants.API_URI_LEAD_NOTES, data)
    .then(resp => {
    	commit(ADD_LEAD_NOTE, resp.data)
    })
  },

  resetLeadNotes ({ commit }) {
    commit(SET_LEAD_NOTES, [])
  }
}

const mutations = {
  [SET_LEADS] (state, leads) {
    state.leads = leads
  },
  [UPDATE_LEAD] (state, lead) {
    const index = state.leads.findIndex(e => (e.id === lead.id))
    console.log(lead)
    console.log(index)
    if (index !== -1) {
      state.leads[index] = lead
    }
    state.lead = lead
  },
  [SET_LEADS_TOTAL_UNFILTERED] (state, num) {
    state.leadsTotalUnfiltered = num
  },
  [SET_LEAD_STATUS_OPTIONS] (state, data) {
    state.leadStatusOptions = data
  },
  [SET_DRIP_CAMPAIGN_OPTIONS] (state, data) {
    state.dripCampaignOptions = data
  },
  [SET_LEAD] (state, lead) {
    state.lead = lead
  },
  [SET_LEAD_NOTES] (state, data) {
    state.leadNotes = data
  },
  [ADD_LEAD_NOTE] (state, data) {
    state.leadNotes.unshift(data)
  }
}

export default {

  state,
  getters,
  actions,
  mutations
}
